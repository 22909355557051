import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, PrimaryButton, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { IProjectProps } from "../../../../../../Models/IProjectProps";
import { Reducer } from "redux";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { ProjectReducerName, projectReducer } from "../../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../../Shared/Sagas/Project.sagas";
import DescriptionHistoryRows from "../../ProjectDescriptionHistory/DescriptionHistoryRows";
import { classificationConfirmationStyles } from './ClassificationConfirmation.styles';
import ReadMore from "../../../../../../Components/V2/Shared/ReadMore";

export type IClassificationConfirmationProps = {
    isOpen?: boolean;
    title?: string;
    onDismiss(): void;
    dialogWidth?: string;
    dialogHeight?: string;
    projectProps?: IProjectProps;
    onConfirmClassify(): void;
}

const ClassificationConfirmation = ({
    isOpen,
    onDismiss,
    projectProps,
    onConfirmClassify,
    dialogWidth,
    dialogHeight,
    title
}: IClassificationConfirmationProps) => {
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const [showFullDescription, setShowFullDescription] = useState(false);

    const handleReadMore = () => {
        setShowFullDescription((prev) => !prev);
    };
    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                showCloseButton: true,
                title: title,
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: "none", overflowY: "hidden" } },
            }}
            aria-label="Classification Confirmation Dialog"
            styles={{
                main: {
                    '@media(min-width: 768px)': {
                        minWidth: dialogWidth || '600px',
                    },
                }
            }}
        >
            <DialogContent>
                <Stack>
                    <Stack style={{ marginTop: -35 }}>
                        <Stack>
                            <strong>Current description</strong>
                        </Stack>
                        <Stack style={{ marginTop: 15 }}>
                            <ReadMore
                                text={projectProps?.description || ""}
                                expand={showFullDescription}
                                onToggle={handleReadMore}
                                maxLength={300} // Define the max length for truncation
                            />
                        </Stack>
                    </Stack>
                    <Stack style={{ marginTop: 15 }}>
                        <DescriptionHistoryRows projectProps={projectProps} shouldShowRevertButton={false} />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmClassify}
                    text="Confirm"
                    className={classificationConfirmationStyles.primaryButtonStyles}
                />
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default ClassificationConfirmation;


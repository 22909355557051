import React, { useCallback, useEffect, useMemo, useState } from "react";
import { descriptionHistoryStyles } from './styles/DescriptionHistory.styles';
import { ProjectDescriptionVersionHistory } from "../../../../../Models/ProjectDescription/ProjectDescriptionVersionHistory";
import { IProjectProps } from "../../../../../Models/IProjectProps";
import { Reducer } from "redux";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { ProjectReducerName, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { useProjectDescriptionHistory } from "./useProjectDescriptionHistory";
import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, IDetailsListStyles, IStackTokens, SelectionMode, Stack } from "@fluentui/react";
import DisplaySpinner from "../../../../../Common/Components/DisplaySpinner";
import MemberView from "../../../../../Components/V2/Components/MemberView";
import ReadMore from "../../../../../Components/V2/Shared/ReadMore";

export interface DescriptionHistoryRowsProps {
    projectProps?: IProjectProps;
    shouldShowRevertButton?: boolean
    onRevertbuttonClicked?: (item: ProjectDescriptionVersionHistory) => void
}

const DescriptionHistoryRows: React.FC<DescriptionHistoryRowsProps> = ({ projectProps, shouldShowRevertButton, onRevertbuttonClicked }) => {

    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

    const {
        isViewingHistory,
        descriptionHistory,
        isLoading,
        fetchDescriptionHistory,
        collapseHistory,
    } = useProjectDescriptionHistory(projectProps, reduxContext);
    const [sortedItems, setSortedItems] = useState<ProjectDescriptionVersionHistory[]>(descriptionHistory || []);
    const [sortConfig, setSortConfig] = useState<{ key: string; isSortedDescending: boolean }>({
        key: '',
        isSortedDescending: false,
    });

    const onColumnClick = (
        event: React.MouseEvent<HTMLElement>,
        column: IColumn
    ) => {
        const { key } = column;
        const isSortedDescending = sortConfig.key === key ? !sortConfig.isSortedDescending : false;

        const sorted = [...sortedItems].sort((a, b) => {
            const aValue = a[key as keyof ProjectDescriptionVersionHistory];
            const bValue = b[key as keyof ProjectDescriptionVersionHistory];

            if (aValue === bValue) {
                return 0;
            }
            if (aValue === undefined || aValue === null) {
                return isSortedDescending ? -1 : 1;
            }
            if (bValue === undefined || bValue === null) {
                return isSortedDescending ? 1 : -1;
            }

            return isSortedDescending
                ? String(bValue).localeCompare(String(aValue))
                : String(aValue).localeCompare(String(bValue));
        });

        setSortConfig({ key, isSortedDescending });
        setSortedItems(sorted);
    };

    const [showFullDescription, setShowFullDescription] = useState<Record<number, boolean>>({});
    const [shouldShowRevertProjectDescriptionConfirmation, setShouldShowRevertProjectDescriptionConfirmation] = useState<boolean>(false)
    const [selectedProjectDescriptionVersion, setselectedProjectDescriptionVersion] = useState<ProjectDescriptionVersionHistory>({})
    const [showFullDescriptionForVersion, setShowFullDescriptionForVersion] = useState(false);
    const handleReadMore = (index: number) => {
        setShowFullDescription((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const revertProjectDescriptionConfirmation = (item: ProjectDescriptionVersionHistory) => {
        setShouldShowRevertProjectDescriptionConfirmation(true)
        setselectedProjectDescriptionVersion(item)
        onRevertbuttonClicked(item)
    }

    const renderDescription = useCallback((
        item: ProjectDescriptionVersionHistory,
        index: number | undefined,
        showFullDescription: Record<number, boolean>,
        handleReadMore: (index: number) => void
    ) => {
        return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
                <ReadMore
                    text={item.projectDescription}
                    expand={!!showFullDescription[index!]}
                    onToggle={() => handleReadMore(index!)}
                    maxLength={50}
                />
            </div>
        );
    }, [showFullDescription, handleReadMore]);
    const handleSort = useCallback((event: React.MouseEvent<HTMLElement>, column: IColumn) => {
        const { key } = column;
        setSortConfig((prev) => ({
            key,
            isSortedDescending: prev.key === key ? !prev.isSortedDescending : false,
        }));
    }, []);

    const columns: IColumn[] = useMemo(() => [
        {
            key: 'versionDate',
            name: 'Version date',
            fieldName: 'versionDate',
            minWidth: 150,
            maxWidth: 200,
            isSorted: sortConfig.key === 'versionDate',
            isSortedDescending: sortConfig.key === 'versionDate' && sortConfig.isSortedDescending,
            onColumnClick,
            onRender: (item: ProjectDescriptionVersionHistory) =>
                item.versionDate,
        },
        {
            key: 'author',
            name: 'Author',
            fieldName: 'author',
            minWidth: 150,
            maxWidth: 200,
            isSorted: sortConfig.key === 'author',
            isSortedDescending: sortConfig.key === 'author' && sortConfig.isSortedDescending,
            onColumnClick,
            onRender: (item: ProjectDescriptionVersionHistory) =>
                <MemberView key={item.author} userAlias={item.author} />
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'projectDescription',
            minWidth: 300,
            isMultiline: true,
            onRender: (item: ProjectDescriptionVersionHistory, index?: number) => renderDescription(item, index, showFullDescription, handleReadMore),
        },
        ...(shouldShowRevertButton
            ? [
                {
                    key: 'revert',
                    name: 'Revert',
                    fieldName: '',
                    minWidth: 100,
                    onRender: (item: ProjectDescriptionVersionHistory) => (
                        <DefaultButton
                            onClick={() =>
                                revertProjectDescriptionConfirmation(item)
                            }
                        >
                            Revert
                        </DefaultButton>
                    ),
                },
            ]
            : []),
    ], [sortConfig, handleSort, renderDescription, shouldShowRevertButton, onRevertbuttonClicked]);
    useEffect(() => {
        setSortedItems(descriptionHistory || []);
    }, [descriptionHistory]);

    const detailsListStyles: Partial<IDetailsListStyles> = {
        headerWrapper: {
            selectors: {
                '.ms-DetailsHeader': {
                    minHeight: 32, // Adjust header height
                    lineHeight: '32px', // Vertically center content
                },
                '.ms-DetailsHeader-cell': {
                    padding: '0 8px', // Reduce padding for tighter spacing
                },
                '.ms-DetailsHeader-cellTitle': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px', // Adds spacing between text and sort icon
                },
            },
        },
    };
    useEffect(() => {
        if (shouldShowRevertButton && !isViewingHistory) {
            fetchDescriptionHistory();
        }
    }, [shouldShowRevertButton, isViewingHistory, fetchDescriptionHistory]);

    const stackTokens: IStackTokens = {
        childrenGap: 20, // Gap between the two stacks
    };
    const handleReadMoreDescription = () => {
        setShowFullDescriptionForVersion((prev) => !prev);
    };
    return (
        <>
            {shouldShowRevertProjectDescriptionConfirmation && (
                <>
                    <Stack className={descriptionHistoryStyles.confirmationText}>
                        Are you sure you want to revert to this previous version?
                    </Stack>
                    <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 15 }}>
                        <Stack horizontal tokens={stackTokens} horizontalAlign="start">
                            <Stack>
                                Version date
                            </Stack>
                            <Stack>
                                {selectedProjectDescriptionVersion.versionDate}
                            </Stack>
                        </Stack>
                        <Stack horizontal tokens={stackTokens} horizontalAlign="start">
                            <Stack style={{ width: '10%' }}>
                                Author
                            </Stack>
                            <Stack>
                                {selectedProjectDescriptionVersion.author}
                            </Stack>
                        </Stack>
                        <Stack horizontal tokens={stackTokens} horizontalAlign="start" >
                            <Stack>
                                Description
                            </Stack>
                            <Stack>
                                <ReadMore
                                    text={projectProps?.description || ""}
                                    expand={showFullDescriptionForVersion}
                                    onToggle={handleReadMoreDescription}
                                    maxLength={300} // Define the max length for truncation
                                />

                            </Stack>
                        </Stack>
                    </Stack>
                </>
            )}
            {!shouldShowRevertProjectDescriptionConfirmation && (<>
                {(shouldShowRevertButton || isViewingHistory) ? (
                    <>
                        {isLoading && (
                            <div style={{ display: 'inline-block', marginLeft: 8 }}>
                                <DisplaySpinner
                                    spinnerText="Loading description history"
                                    spinnerPosition="left"
                                    accessabilityMessage="Loading description history"
                                />
                            </div>
                        )}
                        {!isLoading && (Array.isArray(descriptionHistory) && sortedItems.length > 0 ? (

                            <DetailsList
                                items={sortedItems}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                styles={detailsListStyles}
                            />
                        ) : (
                            <p>No history available.</p>
                        ))}
                        {!shouldShowRevertButton && (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    collapseHistory();
                                }}
                                className={descriptionHistoryStyles.link}
                            >
                                Collapse description history
                            </button>
                        )}

                    </>
                ) : (
                    <Stack horizontal verticalAlign="center">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                fetchDescriptionHistory();
                            }}
                            className={descriptionHistoryStyles.link}
                        >
                            View description history
                        </button>
                    </Stack>
                )}
            </>
            )}
        </>
    )
};

export default DescriptionHistoryRows
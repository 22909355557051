import { IProjectFiles } from "../Models/IProjectFile";

export const getFileExtension = (fileName: string): string => {
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    return extension
}

const getFileName = (fileName: any) => new URL(fileName).pathname.split("/").pop();

export const openEmbedURL = (url?: string) => {
    if (url) {        
        // Check if parameters already exist
        if (url.includes('?')) {
            // Parameters already exist, append &web=1
            url += '&web=1';
        } else {
            // No parameters exist, append ?web=1
            url += '?web=1';
        }
        // Open the modified URL in a new tab
        window.open(url, '_blank');
    };
}

export function combinePaths(metadataStoragePath: string, exportNativePath: string): string {
    // Step 1: Find the index of '.net/' in metadataStoragePath
    const baseIndex = metadataStoragePath.indexOf('.net/') + 5; // Length of '.net/' is 5

    if (baseIndex === 4) {
        console.error('Failed to find .net/ in metadataStoragePath.');
        return '';
    }

    // Find the index of the next '/' after the baseIndex
    const nextSlashIndex = metadataStoragePath.indexOf('/', baseIndex);
    if (nextSlashIndex === -1) {
        console.error('No slash found after .net/ in metadataStoragePath.');
        return '';
    }

    // Find the next '/' to extract the base URL up to and including the container name and litigation ID
    const subsequentSlashIndex = metadataStoragePath.indexOf('/', nextSlashIndex + 1);
    if (subsequentSlashIndex === -1) {
        console.error('Failed to find the next slash after container name and litigation ID.');
        return '';
    }

    // Extract the base URL up to and including the container name and litigation ID
    const baseURL = metadataStoragePath.substring(0, subsequentSlashIndex + 1);

    // Step 2: Combine the base URL with exportNativePath
    const filePath = `${baseURL}${exportNativePath.startsWith('/') ? exportNativePath.slice(1) : exportNativePath}`;

    // Step 3: Return the combined file path
    return filePath;
}


export const highlightMatches = (htmlContent: string, searchKeyword?: string) => {
    if (!searchKeyword || !htmlContent || typeof htmlContent !== 'string') {
        return { __html: htmlContent }; // Return the original content if conditions are not met
    }

    // Create a regular expression with the search keyword and apply global and case-insensitive flags
    const regex = new RegExp(`(${searchKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');

    // Apply highlighting to HTML content
    const highlightedHTML = htmlContent.replace(regex, '<mark>$1</mark>');

    return { __html: highlightedHTML }; // Return as an object with __html property
};
export const convertPlainTextToHTML = (input) => {
    let html;

    try {
        // Ensure input is a string
        if (typeof input !== 'string') {
            input = JSON.stringify(input, null, 2);
        }

        // Check if input is JSON
        if (input.trim().startsWith('{')) {
            const jsonData = JSON.parse(input);

            // Function to recursively convert JSON to HTML
            const jsonToHTML = (json) => {
                let html = '<ul>';

                for (const key in json) {
                    if (json.hasOwnProperty(key)) {
                        const value = json[key];
                        html += `<li><strong>${key}:</strong> `;
                        if (typeof value === 'object' && value !== null) {
                            html += jsonToHTML(value);
                        } else {
                            html += String(value).replace(/&/g, '&amp;')
                                .replace(/</g, '&lt;')
                                .replace(/>/g, '&gt;')
                                .replace(/"/g, '&quot;')
                                .replace(/'/g, '&#039;');
                        }
                        html += '</li>';
                    }
                }

                html += '</ul>';
                return html;
            };

            html = `<html><body>${jsonToHTML(jsonData)}</body></html>`;
        } else {
            // Treat input as regular text
            html = `<html><body><p>${input.replace(/\n/g, '<br>')}</p></body></html>`;
        }
    } catch (error) {
        console.error('Error parsing input:', error);
        // Treat input as regular text if JSON parsing fails
        html = `<html><body><p>${input.replace(/\n/g, '<br>')}</p></body></html>`;
    }

    return html;
};

export const getIconByFileExtension = (fileName: string): string => {
    if (fileName?.startsWith("https")) {
        fileName = getFileName(fileName)
    }
    let extension = getFileExtension(fileName);
    switch (extension.toLowerCase()) {
        case "pdf":
            return "pdf"
        case "pptx":
        case "ppt":
            return "ppt"
        case "docx":
        case "doc":
            return "docx"
        case "png":
        case "jpeg":
        case "jpg":
        case "bmp":
        case "icon":
            return "jpg"
        case "xlsx":
        case "xls":
        case "csv":
            return "xlsx"
        case "txt":
        case "json":
            return "txt"
        case "msg":
            return "msg"
        case "one":
            return "one"
        case "zip":
            return "zip"
        case "msg":
            return "mail"
        default:
            return "txt"
    }
}

export const truncateText = (textToTruncate: string, length: number, numCharsToShow: number): string => {
    return textToTruncate?.length > length ? textToTruncate.substring(0, numCharsToShow) + " ..." : textToTruncate;
}

export const checkIfFileExistsInSPO = (fileName: string, projectFiles: IProjectFiles): boolean => {
    let isNew = true;
    projectFiles?.projectFile?.map((projectFile) => {
        if (projectFile.file.name === fileName) {
            isNew = false;
        }
    });
    return isNew;
}


export const getCurrentDate = (separator = '/') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const getFutureDate = (days: number, separator = "/") => {
    var today = new Date();
    let newDate = new Date(new Date().setDate(today.getDate() + days));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const getPastDate = (days: any, separator = "/") => {
    var today = new Date();
    let newDate = new Date(new Date().setDate(today.getDate() - days));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const formatDate = (date?: Date): string => {
    return !date ? '' : ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getFullYear());
};

export const getDateFromString = (dateString?: string): Date => {
    // var UTCDate = new Date(dateString).toUTCString()
    var date = new Date(dateString)
    date.setHours(date.getHours() + 8);
    return date;
};

export const isObjectsSame = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}



export const getFormattedDate = (dateString?: string): string => {
    if(dateString!=='') {
        var date = new Date(dateString);
        var formattedDate = formatDate(date);
        return formattedDate.toString();
    }
    return '';
};

export const  formatDateString = (dateString: string): string =>{
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  export const  formatDateStringMMMdd = (dateString: string): string =>{
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric'
      });
    return formattedDate;
  }
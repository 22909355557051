
import { IStyle, mergeStyleSets } from '@fluentui/react';

const linkStyles: IStyle = {
    background: 'none',
    border: 'none',
    color: '#0078d4', // Fluent UI primary link color
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
    selectors: {
        ':hover': {
            color: '#005a9e', // Darker shade for hover effect
            textDecoration: 'none', // Optional: remove underline on hover
        },
    },
};

export const descriptionHistoryStyles = mergeStyleSets({
    link: linkStyles,
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        borderBottom: '1px solid #ccc',
        textAlign: 'left',
        padding: '8px',
        backgroundColor: '#f4f4f4', // Optional: header background color
    },
    tableRow: {
        borderBottom: '1px solid #ccc',
        marginBottom: 5,
        marginTop: 5
    },
    tableCell: {
        padding: '8px',
        textAlign: 'left',
    },
    preFormat: {
        whiteSpace: 'pre-wrap', wordWrap: 'break-word'
    },
    confirmationText: {
        marginTop: -40, fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        WebkitFontSmoothing: "antialiased",
        fontSize: "20px",
        fontWeight: 600,
        color: "rgb(50, 49, 48)",
        minHeight: "20px",
        lineHeight: "normal",
    }
});

import React from "react";
import { IStyle, mergeStyles } from "@fluentui/style-utilities";
export const preFormatStyle: React.CSSProperties = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
};
const linkStyles: IStyle = {
    background: "none",
    border: "none",
    color: "#0078d4", // Fluent UI primary link color
    textDecoration: "none",
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
    selectors: {
        ":hover": {
            color: "#005a9e", // Darker shade for hover effect
            textDecoration: "none", // Optional: remove underline on hover
        },
    },
};
const linkClassName = mergeStyles(linkStyles);
export interface IReadMoreProps {
    text: string;
    maxLength?: number;
    expand: boolean;
    onToggle: () => void;
}
const ReadMore: React.FC<IReadMoreProps> = ({ text, maxLength = 300,expand, onToggle }) => {    
    return (
        <div style={preFormatStyle}>
            {expand ? (
                <>
                    {text}{' '}
                    <button
                        className={linkClassName}
                        onClick={onToggle}
                    >
                        Show less
                    </button>
                </>
            ) : (
                <>
                    {text.slice(0, maxLength)}
                    {text.length > maxLength && (
                        <>
                            ...{' '}
                            <button
                                 className={linkClassName}
                                onClick={onToggle}
                            >
                                Read more
                            </button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default ReadMore
import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { IProjectProps } from "../../../../Models/IProjectProps";
import { Reducer } from "redux";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { ProjectReducerName, projectReducer } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import DescriptionHistoryRows from "./ProjectDescriptionHistory/DescriptionHistoryRows";

import { ProjectDescriptionVersionHistory } from "../../../../Models/ProjectDescription/ProjectDescriptionVersionHistory";


export type IProjectDescriptionHistoryModalProps = {
    isOpen?: boolean;
    title?: string;
    onDismiss(): void;
    dialogWidth?: string;
    dialogHeight?: string;
    projectProps?: IProjectProps;
    onRevertbuttonClicked?: () => void
    updateToSelectedPojectDescriptionVersion?: (item: ProjectDescriptionVersionHistory)=> void
}
const ProjectDescriptionHistoryModal = ({
    isOpen,
    onDismiss,
    projectProps,
    dialogWidth,
    dialogHeight,
    title,
    updateToSelectedPojectDescriptionVersion
}: IProjectDescriptionHistoryModalProps) => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showRevertButton, setShowRevertButton] = useState(false);
    const [selectedProjectDescriptionVersion, setSelectedProjectDescriptionVersion] = useState<ProjectDescriptionVersionHistory>({});
    const onRevertbuttonHandler = (item: ProjectDescriptionVersionHistory) => {
        setShowRevertButton(true)
        setSelectedProjectDescriptionVersion(item)
    }

    const onRevertProjectDescriptionToSelectedVersion = ()=>{        
        updateToSelectedPojectDescriptionVersion(selectedProjectDescriptionVersion);
        onDismiss();
    }
    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                showCloseButton: true,
                title: title,
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: "none", overflowY: "hidden" } },
            }}
            styles={{
                main: {
                    '@media(min-width: 768px)': {
                        minWidth: dialogWidth || '600px',
                    },
                }
            }}
        >
            <DialogContent>
                <Stack>
                    <Stack style={{ marginTop: 15 }}>
                        <DescriptionHistoryRows projectProps={projectProps} shouldShowRevertButton={true} onRevertbuttonClicked={onRevertbuttonHandler} />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogFooter>
                {!showRevertButton && (<DefaultButton onClick={onDismiss} text="Close" />)}
                {showRevertButton && (<>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton text="Revert" onClick={onRevertProjectDescriptionToSelectedVersion}/>
                        <DefaultButton onClick={onDismiss} text="Close" />
                    </Stack>
                </>)}

            </DialogFooter>
        </Dialog>
    );

}

export default ProjectDescriptionHistoryModal
import * as React from "react";
import { ProjectAPIToken } from "../../Services/ProjectAPI";
import { AxiosError } from "axios";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
const useGraphToken = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);    
  const { authClient } = reduxContext;
  const [isGraphTokenLoading, setIsGraphTokenLoading] = React.useState(false);
  const [graphToken, setGraphToken] = React.useState<string>("");
  const getProjectAPIToken = async (): Promise<any> => {
    try {
      const lmiApiToken = await ProjectAPIToken.getGraphToken();
      setIsGraphTokenLoading(false);
      return lmiApiToken;
    } catch (error: any | AxiosError) {
      setIsGraphTokenLoading(false);
      return "";
    }
  };

  React.useEffect(() => {
    setIsGraphTokenLoading(true);
    const fetchLmiToken = async () => {
      try {
        const lmiToken = await getProjectAPIToken();
        setGraphToken(lmiToken);
        setIsGraphTokenLoading(false);
      } catch (error: any) {
        console.log(error);
      }
    };
    fetchLmiToken();
  }, []);

  return { isGraphTokenLoading, graphToken };
};
export default useGraphToken;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Reducer } from "redux";
import { Dialog, PrimaryButton, DialogType, Stack, DialogContent, Icon, TooltipHost } from '@fluentui/react';
import { ILitigationFileContentRequest } from 'src/Models/ILitigationCaseRequest';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { requestLitigationFileContent, requestLitigationFileURL } from "../../../../../Shared/Actions/Project.action";
import DisplaySpinner from "../../../../../Common/Components/DisplaySpinner";
import './FileContentDialog.css';
import { ILitigationCase, ILitigationCaseFiles } from 'src/Models/ILitigationCase';
import MarkdownIt from 'markdown-it';
import FileHistory from './FileHistory/FileHistory';
import { combinePaths, convertPlainTextToHTML, highlightMatches, openEmbedURL } from '../../../../../Helpers/ProjectHelper';
import DOMPurify from 'dompurify';
export interface IFileContentProps {
    isOpen?: boolean;
    title?: string;
    onDismiss(): void;
    dialogWidth?: string;
    dialogHeight?: string;
    nativeFilePath?: string;
    item?: ILitigationCaseFiles;
    items?: ILitigationCaseFiles[];
    cases?: ILitigationCase[]
}

const FileContentDialog = (fileProps: IFileContentProps) => {
    const { isOpen, onDismiss, dialogHeight, nativeFilePath, item, items, cases } = fileProps;
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        litigationFileContentResponses,
        isLitigationFileContentResultsLoading,
        litigationFileURLResponses,
        isLitigationFileURLResultsLoading,
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    //const [fileContent, setFileContent] = React.useState('');
    const [caseFile, setCaseFile] = React.useState<ILitigationCaseFiles>(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [, setSearchPositions] = useState([]);
    const [, setSearchVisible] = useState(false);
    const [, setJustification] = useState('');
    const [currentCaseFile, setCurrentCaseFile] = useState<ILitigationCaseFiles>({});
    const [isFirstItem, setIsFirstItem] = useState<boolean>(false);
    const [isLastItem, setIsLastItem] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const searchRef = useRef(null);
    // Example usage:
    const handleKeyPress = (e) => {
        // Check if Ctrl+F is pressed
        if (e.ctrlKey && e.key === 'f') {
            setSearchVisible(true);
            e.preventDefault(); // Prevent the browser's default "Find" dialog from showing up
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        if (currentCaseFile?.justification) {
            const renderJustification = new MarkdownIt().render(currentCaseFile?.justification)
            setJustification(renderJustification)
        }
    }, [currentCaseFile]);

    useEffect(() => {
        if (item) {
            setCurrentCaseFile(item)
        }
    }, [item]);
    useEffect(() => {
        if (litigationFileContentResponses && litigationFileContentResponses?.litigationCaseFiles) {
            setCaseFile(litigationFileContentResponses?.litigationCaseFiles)
        }
    }, [litigationFileContentResponses]);

    let searchTimeout;

    const handleSearch = useCallback((value?: string) => {
        setSearchKeyword(value); // Update search input state immediately
        if (value.trim() === '') {
            // If the search string is empty, reset search positions immediately
            setSearchPositions([]);
            return; // Exit early to prevent starting the debounce timeout
        }
        clearTimeout(searchTimeout); // Clear previous timeout
        searchTimeout = setTimeout(() => {
            const regex = new RegExp(value, 'gi');
            const matches = [];
            let match;
            while ((match = regex.exec(caseFile?.content)) !== null) {
                matches.push(match.index);
            }
            setSearchPositions(matches);
        }, 300); // Adjust the delay as needed (e.g., 300 milliseconds)
    }, [caseFile?.content]);

    const updateFileState = (nextIndex: number) => {
        setIsFirstItem(nextIndex === 0);
        setIsLastItem(nextIndex === items.length - 1);
        setCurrentCaseFile(items[nextIndex]);
    };
    const handleNextFile = () => {
        if (currentCaseFile) {
            const currentIndex = items?.findIndex((file) => file.id === currentCaseFile.id);
            const nextIndex = (currentIndex + 1) % items.length;
            setCurrentIndex(nextIndex);
            updateFileState(nextIndex);
        }
    };
    const handlePrevFile = () => {
        if (currentCaseFile) {
            const currentIndex = items?.findIndex((file) => file.id === currentCaseFile.id);
            const nextIndex = (currentIndex - 1 + items.length) % items.length;
            setCurrentIndex(nextIndex);
            updateFileState(nextIndex);
        }
    };

    useEffect(() => {
        if (Object.keys(currentCaseFile)?.length > 0 && isFirstItem == false) {
            let nativePath = combinePaths(currentCaseFile.metadata_storage_path, currentCaseFile.export_native_path);
            const caseInfo: ILitigationCase = cases?.find(x => x.caseId === currentCaseFile.caseId);;
            const litigationFileContentRequest: ILitigationFileContentRequest = {
                userAlias: loginUserpersonaProps?.secondaryText,
                fileName: currentCaseFile.metadata_storage_name,
                fileId: currentCaseFile.id,
                caseInfo: caseInfo,
                isKeywordSearch: false,
                nativeFilePath: nativePath
            }
            dispatch(requestLitigationFileContent(litigationFileContentRequest));
            dispatch(requestLitigationFileURL(litigationFileContentRequest));
        }

    }, [currentCaseFile])

    useEffect(() => {
        if (items && item) {
            const currentIndex = items.findIndex((file) => file.id === item.id);
            setCurrentIndex(currentIndex);
            setIsFirstItem(currentIndex === 0);
            setIsLastItem(currentIndex === items.length - 1);
        }
    }, [items, item]);
    const buttonStyles = {
        root: {
            borderRadius: "50%", // Makes the button rounded
            width: "50px",       // Set width to create a circular shape
            height: "50px",      // Set height to match width
            minWidth: "50px",    // Prevents the button from shrinking
            padding: 0,          // Removes extra padding
        },
        label: {
            fontSize: "20px",    // Adjust the size of the '>' symbol
        },
    };
    return (
        <>
            <Dialog
                hidden={!isOpen}
                onDismiss={() => {
                    onDismiss();
                    setSearchKeyword('');
                    setSearchPositions([]);
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    showCloseButton: true,
                    styles: { content: { height: '80vh' } }
                }}
                modalProps={{
                    isBlocking: false,
                    styles: { main: { maxWidth: 'none', overflowY: 'hidden' } },
                }}
                styles={{
                    main: {
                        '@media(min-width: 768px)': {
                            minWidth: window.innerWidth - 50 || '600px',
                            height: '99vh'
                        },
                    }
                }}

            >

                <DialogContent styles={{
                    content: {
                        height: '80vh', // Set height
                        overflowY: 'auto', // Allow vertical scrolling
                    },
                }}>

                    {isLitigationFileContentResultsLoading && (
                        <Stack style={{ marginTop: 0 }}>
                            <span role="alert" aria-label="Loading file content" aria-live="assertive" />
                            <DisplaySpinner
                                accessabilityMessage="Loading file content"
                                spinnerText="Loading file content..."
                                spinnerPosition="right"
                            />
                        </Stack>
                    )}
                    {!isLitigationFileContentResultsLoading && caseFile && (

                        <div style={{ height: dialogHeight || '100vh' }}>
                            {items?.length > 0 && (
                                <Stack horizontal style={{ marginTop: -10 }} className='button-container'>
                                    <TooltipHost content="Previous case file" calloutProps={{ gapSpace: 0 }}>
                                        <PrimaryButton className={`rounded-button ${isFirstItem ? "hidden" : ""}`} styles={{
                                            label: "rounded-button-label"
                                        }} text="<" tabIndex={isFirstItem ? -1 : 0} onClick={handlePrevFile} />
                                    </TooltipHost>
                                    <Stack horizontal>
                                        <span style={{ fontWeight: 'bold' }}>{currentIndex + 1} of {items?.length}</span>
                                    </Stack>
                                    <TooltipHost content="Next case file" calloutProps={{ gapSpace: 0 }}>
                                        <PrimaryButton className={`rounded-button ${isLastItem ? "hidden" : ""}`} styles={{
                                            label: "rounded-button-label"
                                        }} text=">" tabIndex={isLastItem ? -1 : 0} onClick={handleNextFile} />
                                    </TooltipHost>
                                </Stack>
                            )}
                            <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }} style={{ marginBottom: 10 }}>

                                <Stack style={{ marginLeft: 'auto', width: '40%', minWidth: '300px' }}>
                                    <input
                                        ref={searchRef}
                                        type="text"
                                        placeholder="Find"
                                        value={searchKeyword}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        style={{ height: '40px' }}
                                    />

                                </Stack>
                                <Stack>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {isLitigationFileURLResultsLoading ? (
                                            <button disabled className="loading-button">
                                                <div className="spinner-container">
                                                    <div className="spinner"></div>
                                                </div>
                                                <span>Loading...</span>
                                            </button>
                                        ) : (
                                            <PrimaryButton onClick={() => openEmbedURL(litigationFileURLResponses.embedURL)}
                                                className='primary-button'>
                                                <Icon iconName="EdgeLogo" className='icon-edge-logo' />
                                                Native file
                                            </PrimaryButton>
                                        )}
                                    </div>
                                </Stack>
                            </Stack>
                            <div>
                                <table className="file-content-table">
                                    <tbody>
                                        {caseFile?.metadata_storage_name && (
                                            <tr>
                                                <th className='columnName'>File Name</th>
                                                <td className='columnValue'>{currentCaseFile?.metadata_storage_name}</td>
                                            </tr>
                                        )}
                                        {nativeFilePath && (
                                            <tr>
                                                <th className='columnName'>File Path</th>
                                                <td className='columnValue'>{nativeFilePath}</td>
                                            </tr>
                                        )}
                                        {caseFile?.file_class && (
                                            <tr>
                                                <th className='columnName'>File Class</th>
                                                <td className='columnValue'>{currentCaseFile?.file_class}</td>
                                            </tr>
                                        )}
                                        {caseFile?.custodian && (
                                            <tr>
                                                <th className='columnName'>Custodian</th>
                                                <td className='columnValue'>{currentCaseFile?.custodian}</td>
                                            </tr>
                                        )}
                                        {caseFile?.user_tags && (
                                            <tr>
                                                <th className='columnName'>User Tags</th>
                                                <td className='columnValue'>{currentCaseFile?.user_tags}</td>
                                            </tr>
                                        )}
                                        {caseFile?.justification && (
                                            <tr>
                                                <th className='columnName'>Justification</th>
                                                <td className='columnValue'>
                                                    <div className='break-word-normal' dangerouslySetInnerHTML={{ __html: currentCaseFile?.justification }} />
                                                </td>
                                            </tr>
                                        )}
                                        {caseFile?.decision && (
                                            <tr>
                                                <th className='columnName'>Relevant to the Case</th>
                                                <td className='columnValue'>{currentCaseFile?.decision}</td>
                                            </tr>
                                        )}
                                        {caseFile?.fileRelevanceScore && (
                                            <tr>
                                                <th className='columnName'>Relevance</th>
                                                <td className='columnValue'>{currentCaseFile?.fileRelevanceScore}</td>
                                            </tr>
                                        )}
                                        {caseFile?.documentTheme && (
                                            <tr>
                                                <th className='columnName'>Document theme</th>
                                                <td className='columnValue'>{currentCaseFile?.documentTheme}</td>
                                            </tr>
                                        )}
                                        {caseFile?.documentSummary && (
                                            <tr>
                                                <th className='columnName'>Document summary</th>
                                                <td className='columnValue'>{currentCaseFile?.documentSummary}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th className='columnName'>Classification History</th>
                                            <td className='columnValue'>
                                                <Stack style={{ marginLeft: 10 }}>
                                                    <FileHistory id={currentCaseFile?.id} fileId={currentCaseFile?.file_ID} />
                                                </Stack>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30, paddingBottom: 40 }} dangerouslySetInnerHTML={highlightMatches(DOMPurify.sanitize(convertPlainTextToHTML(caseFile?.content)), searchKeyword)} />
                        </div>


                    )}
                </DialogContent>

            </Dialog>
        </>
    );
};

export default FileContentDialog;

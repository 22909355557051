import { Announced, Callout, Checkbox, DefaultButton, Dialog, DialogFooter, Dropdown, IDropdownOption, Icon, IconButton, MessageBarType, Modal, Panel, PanelType, PrimaryButton, SearchBox, Stack, Sticky, StickyPositionType, TextField, Toggle, TooltipHost, mergeStyleSets } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { FlatTree, FlatTreeItem, HeadlessFlatTreeItemProps, Tree, TreeItem, TreeItemLayout, useHeadlessFlatTree_unstable } from "@fluentui/react-components";
import { useHistory } from "react-router-dom";
import { useCreateNavContext } from "../CreateNavContext/CreateNavContext";
import SidePanelAI from "../../../AIChat/SidePanelAI";
import Button from "../Helpers/Button/Button";
import { ILitigationCase, ILitigationCaseData, ILitigationCaseFiles, ILitigationCaseResponses } from "../../../../../Models/ILitigationCase";
import { ILitigationCaseDataRequest, ILitigationCaseFilesRequest, ILitigationFileContentRequest, ILitigationCaseRequest } from "../../../../../Models/ILitigationCaseRequest";
import { requestDownloadLitigationCaseFiles, requestLitigationCaseData, requestLitigationCaseFiles, requestLitigationFileContent, setCaseFilesFilterListDetails, requestLitigationCases } from "../../../../../Shared/Actions/Project.action";
import { Image20Regular, Folder20Filled, Folder20Regular, Person20Regular, Warning20Regular } from "@fluentui/react-icons";
import { AISearchChat } from "../../../AIChat/AIChat";
import ErrorDisplay from "../../../../../Common/Components/ErrorDisplay";
import DisplaySpinner from "../../../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../../../Helpers/ProjectConstants";
import FileContentDialog from "./FileContentDialog";
import TagContentDialog from "./TagContentDialog";
import ReviewerContentDialog from "./ReviewerContentDialog";
import DisplayMessage from "../../../../../Common/Components/DisplayMessage";
import AdvanceFilter from "./AdvanceFilter";
import { Pagination } from "../../../../../Components/Shared/Components/Pagination";
import { truncateText } from "../../../../../Helpers/ProjectHelper";
import CaseFilesDetailList from "./CaseFilesDetailsList";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { useAppContext } from './LitigationFilterContext';
import ChatHome from "../../../LitigationChat/ChatHome";
import { setNewUserPrompt } from "../../../../../Shared/Actions/Chat.action";
import View from "src/Components/V2/History/View";
import './FileManagement.Styles.css'
import ClassifyFiles from "./ClassifyFiles";
import ClassificationStatus from "./ClassificationStatus";

import { useMediaQuery } from "@mui/material";

const options: IDropdownOption[] = [
    { key: 50, text: '50' },
    { key: 100, text: '100' },
    { key: 150, text: '150' },
    { key: 200, text: '200' },
];

const FileManagement = ({ isRPSelected }) => {
    const focusSearch: any = React.useRef();

    React.useEffect(() => {
        if (focusSearch?.current) focusSearch?.current?.focus();
    }, [focusSearch]);

    let history = useHistory();
    const { updateNavItem } = useCreateNavContext();
    const handleContinueClick = () => {
        if (isRPSelected) {
            updateNavItem(['fileAttachment', 'firm'], 'firm');
            history.push('/createproject?componentName=firm');
        }
        else {
            history.push('/')
        }
    }
    const handleCancelClick = () => {
        //clear the state
        history.push('/')
    }
    const { advancefilters, searchBoxText, selectedFiles, selectedCaseFiles, selectView, selectedFolders, selectedExportFolders, addSelectedFiles, clearFilterContext, addSearchText, addSelectView, addSelectedFolders, addSelectedExportFolders } = useAppContext();
    //let location = useLocation();

    const { mainSidePanel, subMenuSidePanel } = useCreateNavContext();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const [litigationChatFolder, setLitigationChatFolder] = React.useState<boolean>(false);
    const [loadCasesIdsOnFolderClick, setLoadCasesIdsOnFolderClick] = React.useState<string[]>([]);

    const {
        projectContext,
        projectFiles,
        projectProps,
        litigationCaseDataResponses,
        isLitigationCaseDataResultsLoading,
        isLitigationCaseDataResultsLoadingError,
        litigationCaseDataResponsesError,
        litigationCaseFilesResponses,
        isLitigationCaseFilesResultsLoading,
        isLitigationCaseFilesResultsLoadingError,
        litigationCaseFilesResponsesError,
        litigationFileContentResponses,
        litigationCaseResponses,
        isLitigationCaseResultsLoading,
        isLitigationCaseResultsLoadingError,
        litigationCaseResponsesError,
        isLoadedInBrowser,
        litigationClassifyFileResponseError,
        isFilesClassificationError,
        isFilesClassificationLoading,
        litigationClassifyFileResponse,
        isLoadingSaveUserQuery,
        isSaveUserQueryError,
        isSaveUserQuerySuccess,
        isLoadingUpdateUserQuery,
        isUpdateUserQueryError,
        isUpdateUserQuerySuccess,
        isLoadingDeleteUserQuery,
        isDeleteUserQueryError,
        isDeleteUserQuerySuccess,



    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const { loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );
    const [totalRecords, setTotalRecords] = React.useState(litigationCaseFilesResponses?.totalRecords ? litigationCaseFilesResponses.totalRecords : 0);
    const [previousTotalRecords, setPreviousTotalRecords] = React.useState(litigationCaseFilesResponses?.totalRecords ? litigationCaseFilesResponses.totalRecords : 0);
    const [showLitigationChatModal, setShowLitigationChatModal] = React.useState(false);
    const [previousCaseFiles, setPreviousCaseFiles] = React.useState<ILitigationCaseFiles[]>([]);

    const handleOpenLitigationChatModal = () => {
        dispatch(setNewUserPrompt(''))
        setShowLitigationChatModal(p => !p)
    }

    const setChatFilterQuery = () => {
        Cases?.map((item) => {
            let searchFilter = GetSearchQuery(item.caseId, "", false);
            let searchQueryInFiles: string = "";
            if (selectedFiles && selectedFiles?.length > 0) {
                searchQueryInFiles = " search.in(id,'" + selectedFiles.join(',') + "', ',')";
                if (searchFilter?.length === 0) {
                    searchFilter = searchQueryInFiles;
                } else {
                    searchFilter = searchFilter + " and " + searchQueryInFiles
                }
            }
            item.filter = searchFilter;
        });
        setCases(Cases);
    }

    React.useEffect(() => {
        setChatFilterQuery()
    }, [selectedFiles])

    React.useEffect(() => {
        setChatFilterQuery()
    }, [advancefilters])

    //


    //const itemsPerPage = 50;
    const [pageIndex, setPageIndex] = React.useState(0);
    const [searchText, setSearchText] = React.useState('');
    const [Cases, setCases] = React.useState<ILitigationCase[]>([]);
    const [CaseData, setCaseData] = React.useState<ILitigationCaseData[]>([]);
    const [CaseFiles, setCaseFiles] = React.useState<ILitigationCaseFiles[]>([]);
    const [showTagModal, setShowTagModal] = React.useState(false);
    const [showAdvanceFilterModal, setShowAdvanceFilterModal] = React.useState(false);
    const [showReviewerModal, setShowReviewerModal] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState<number>(0);
    const [selectedTags, setSelectedTags] = React.useState<string[]>([]); // Add this state to store selected tags
    const [selectedView, setselectedView] = React.useState<string>();
    const [selectedViewKey, setselectedViewKey] = React.useState<string>();
    const [currentlySelectedRows, setCurrentlySelectedRows] = React.useState<ILitigationCaseFiles[]>([]);//ILitigationCaseFiles[]
    const [shouldRunEffect, setShouldRunEffect] = React.useState(false);
    const [viewTypes, setViewTypes] = React.useState<IDropdownOption[]>();
    const [defaultViewTypeKey, setdefaultViewTypeKey] = React.useState('');
    const [checkboxAllChecked, setCheckboxAllChecked] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isChatExpanded, setIsChatExpanded] = React.useState(false);
    const [showSaveTagSuccessMessage, setShowSaveTagSuccessMessage] = React.useState(false);
    const [showFileClassificationSuccessMessage, setShowFileClassificationSuccessMessage] = React.useState(false);
    const [userFolders, setUserFolders] = React.useState('');
    const [disableFolderButton, setDisableFolderButton] = React.useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = React.useState(50);
    const [localExportFolders, setLocalExportFolders] = React.useState<string[]>([]);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleChatExpand = () => {
        setIsChatExpanded(!isChatExpanded);
    };
    const viewTypeOptions: IDropdownOption[] = [];
    //----------------Folder view start-----------
    const selectionRef = React.useRef(new Selection({
        onSelectionChanged: () => {
            const selectedItems = selectionRef.current.getSelection();
            setCurrentlySelectedRows(selectedItems as ILitigationCaseFiles[])
            setSelectedItems(selectedItems.length); // Update the state with the number of selected items
        },
    }));

    React.useEffect(() => {
        setShouldRunEffect(true);
        if (advancefilters && (Object.keys(advancefilters)?.length >= 1 && advancefilters[0].fieldKey === '')) {
            clearFilterContext();
        }
        litigationCaseDataResponses.litigationCaseDataResponses = [];
        litigationCaseFilesResponses.litigationCaseFiles = [];
        litigationCaseFilesResponses.totalRecords = 0;
        if (projectProps?.caseIds?.length > 0) {
            if (Object.keys(Cases).length === 0) {
                const litigationCaseRequest: ILitigationCaseRequest = {
                    userAlias: loginUserpersonaProps?.secondaryText,
                    cases: projectProps?.caseIds
                }
                dispatch(requestLitigationCases(litigationCaseRequest));
            }
        }
    }, []);

    React.useEffect(() => {
        if (searchBoxText && searchBoxText.length > 0) {
            setSearchText(searchBoxText);
        }
    }, [searchBoxText]);

    React.useEffect(() => {
        if (shouldRunEffect) {
            setShouldRunEffect(false);
            if (litigationCaseResponses?.litigationCaseResponses?.length > 0) {
                let viewTypeKey = "";
                let defaultKey = "";
                let currentCases = litigationCaseResponses?.litigationCaseResponses;
                setCases(currentCases);
                if (litigationCaseResponses?.litigationCaseResponses.length > 0) {
                    if (litigationCaseResponses?.litigationCaseResponses.length > 1) {
                        viewTypeOptions.push({ id: "default", key: "default", text: "All Cases", ariaLabel: "All Cases" });
                        viewTypeKey = defaultKey = "default";
                    } else {
                        viewTypeKey = litigationCaseResponses?.litigationCaseResponses?.[0].caseName;
                        defaultKey = "cases";
                    }
                    litigationCaseResponses?.litigationCaseResponses.map((item) => {
                        viewTypeOptions.push({ id: "cases", key: item.caseName, text: item.caseName, ariaLabel: item.caseName });
                    });
                    viewTypeOptions.push({ id: "Custodian", key: "Custodian", text: "Custodian", ariaLabel: "Custodian" });
                }
                setViewTypes(viewTypeOptions);
                if (currentCases?.length > 0) {
                    if (defaultKey === "cases") {
                        currentCases.map((item) => { item.isSelected = true; });
                    }
                    const litigationCaseDataRequest: ILitigationCaseDataRequest = {
                        userAlias: loginUserpersonaProps?.secondaryText,
                        cases: currentCases,
                        viewType: defaultKey
                    }
                    setselectedView(defaultKey);
                    setselectedViewKey(viewTypeKey);
                    addSelectView(viewTypeKey);
                    setdefaultViewTypeKey(viewTypeKey);
                    dispatch(requestLitigationCaseData(litigationCaseDataRequest));
                }
            }
        }
    }, [litigationCaseResponses]);

    React.useEffect(() => {
        setCaseData([]);
        if (litigationCaseDataResponses?.litigationCaseDataResponses?.length > 0) {
            setCaseData(litigationCaseDataResponses?.litigationCaseDataResponses);
        }
    }, [litigationCaseDataResponses]);

    React.useEffect(() => {
        setCaseFiles([]);
        setPreviousTotalRecords(totalRecords);
        setTotalRecords(litigationCaseFilesResponses?.totalRecords ? litigationCaseFilesResponses.totalRecords : 0);
        if (litigationCaseFilesResponses) {
            if (litigationCaseFilesResponses?.totalRecords > 0 && litigationCaseFilesResponses?.litigationCaseFiles?.length > 0) {
                setCaseFiles(litigationCaseFilesResponses.litigationCaseFiles);
            }
        }
    }, [litigationCaseFilesResponses]);

    const clearFolderSelection = () => {
        //clear the folders on view type change
        CaseData.forEach((item) => {
            item.isChecked = false;
        });
    }

    const onViewTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item.id) {
            setCheckboxAllChecked(false);
            clearFolderSelection();
            setselectedView(item.id as string);
            addSelectView(item.key as string);
            setselectedViewKey(item.key as string);
            addSelectView(item.key as string);
            Cases.map((caseItem) => {
                caseItem.isSelected = (caseItem.caseName === item.text);
            });
            if (item.id === "project") {
                litigationCaseFilesResponses.totalRecords = projectFiles?.projectFile?.length;
                //get project documents 
                //check if project is created
                if (projectContext?.number) {
                    const projectCaseFiles: ILitigationCaseFiles[] = [];
                    projectFiles?.projectFile?.map((file) => {
                        projectCaseFiles.push({
                            metadata_storage_name: file.file?.name,
                            native_type: "txt"
                        }
                        );
                    });
                    setCaseFiles(projectCaseFiles);
                }
            }
            else {
                const litigationCaseDataRequest: ILitigationCaseDataRequest = {
                    userAlias: loginUserpersonaProps?.secondaryText,
                    cases: Cases,
                    viewType: item.id as string
                }
                dispatch(requestLitigationCaseData(litigationCaseDataRequest));
            }
            setCurrentlySelectedRows([])
            setSelectedItems(0);
            addSelectedFiles([]);
            setPageIndex(0);
            SearchExecution(0, "");
        }
    }

    const handleCheckboxChange = (item: ILitigationCaseData) => (ev: any, checked: boolean) => {
        setPageIndex(0);
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);

        // Handle checkbox change event here
        CaseData.find((x) => x.custodian === item.custodian && x.caseId == item.caseId).isChecked = checked;
        updateSelectedFolders(item.custodian, checked);
        updateSelectedExportFolders(item.custodian, checked);
        Cases?.forEach((caseItem) => {
            if (CaseData.some((custodian) => custodian.caseId === caseItem.caseId && custodian.isChecked)) {
                caseItem.isSelected = true;
            } else {
                caseItem.isSelected = false;
            }
        });
        if (CaseData?.every((val) => val.isChecked)) {
            setCheckboxAllChecked(true);
            updateSelectedFolders("All", true);
        } else if (checkboxAllChecked) {
            setCheckboxAllChecked(false);
        }
        SearchExecution(0, "");
    };

    const handleCheckboxAllChange = (ev: any, checked: boolean) => {
        setPageIndex(0);
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        // If the checkbox is in the indeterminate state, uncheck all items
        if (checkboxAllChecked && CaseData?.some((val) => val.isChecked)) {
            setCheckboxAllChecked(false);
            CaseData?.forEach((item) => { item.isChecked = false; updateSelectedExportFolders(item.custodian, item.isChecked) });
            Cases?.forEach((caseItem) => { caseItem.isSelected = false; });
        } else {
            setCheckboxAllChecked(checked);
            CaseData?.forEach((item) => { item.isChecked = checked; updateSelectedExportFolders(item.custodian, item.isChecked) });
            Cases?.forEach((caseItem) => {
                // Check if caseItem exists in CaseData before setting isSelected to true
                if (CaseData?.some((dataItem) => dataItem.caseId === caseItem.caseId) && caseItem.isSelected !== checked) {
                    caseItem.isSelected = checked;
                }
            });
        }
        updateSelectedFolders("All", checked);

        SearchExecution(0, "");
    };

    const updateSelectedFolders = (id, isChecked) => {
        if (id === "All") {
            if (isChecked) {
                // If "Select All" is checked, save "All" and clear the list
                addSelectedFolders("All");
            } else {
                // If "Select All" is unchecked, clear the list
                addSelectedFolders("");
            }
        } else {
            // Split the comma-separated string into an array
            let foldersArray = selectedFolders ? selectedFolders.split(',') : [];

            if (isChecked) {
                // Add the ID if it is not already in the array
                if (!foldersArray.includes(id)) {
                    foldersArray.push(id);
                }
            } else {
                // Remove the ID if it is in the array
                foldersArray = foldersArray.filter(folderId => folderId !== id);
            }

            // If the array contains "All", remove it
            foldersArray = foldersArray.filter(folderId => folderId !== "All");

            // Update the selectedFolders with the new comma-separated string
            addSelectedFolders(foldersArray.join(','));

            addSelectedExportFolders(foldersArray.join(','));
        }
    };
    const updateSelectedExportFolders = (id, isChecked) => {
        setLocalExportFolders(prevFolders => {
            let foldersArray = prevFolders ? [...prevFolders] : [];

            if (isChecked) {
                // Add the ID if it is not already in the array
                if (!foldersArray.includes(id)) {
                    foldersArray.push(id);
                }
            } else {
                // Remove the ID if it is in the array
                foldersArray = foldersArray.filter(folderId => folderId !== id);
            }

            // If the array contains "All", remove it
            foldersArray = foldersArray.filter(folderId => folderId !== "All");

            // Return the updated array
            return foldersArray;
        });
    };
    React.useEffect(() => {
        // Update the main state when localExportFolders changes
        if (localExportFolders.length > 0) {
            const updatedFolders = localExportFolders.join(',');
            addSelectedExportFolders(updatedFolders);
            console.log("Updated selectedExportFolders:", updatedFolders);
        }
    }, [localExportFolders]);


    //----------------Folder view end--------------

    const [showChat, setshowChat] = React.useState(false);
    const handleIconClick = () => {
        setshowChat(true);
    };

    function onDismissAiSearchPanel(): void {
        setshowChat(false);
    }

    const handleDismissClick = () => {
        setShowTagModal(false);
    };


    const onChangeTagSelection = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {

        if (item.selected) {
            setSelectedTags([...selectedTags, item.key as string]); // Add the selected tag to the state
        }
    }
    const refreshCaseDetailsView = () => {
        setShowSaveTagSuccessMessage(true);
        //SearchExecution(pageIndex, "");
    };

    const refreshCaseDetailsViewForFileClassificationSuccess = (files: ILitigationCaseFiles[]) => {
        setShowFileClassificationSuccessMessage(true);
        setCaseFiles([...files])
    };
    const addTags = () => {
        setSelectedTags([]);
        const selectedRows = selectionRef.current.getSelection() as ILitigationCaseFiles[];
        //setTextAreaValue(selectedRows.map((item: ILitigationCaseFiles) => item?.fileName).join(', '))
        setShowTagModal(true);

    };

    const addReviewers = () => {
        // setSelectedTags([]);
        // const selectedRows = selectionRef.current.getSelection() as ILitigationCaseFiles[];
        // setTextAreaValue(selectedRows.map((item: ILitigationCaseFiles) => item.native_File_Name).join(', '))
        // setShowReviewerModal(true);

    };

    const handleAdvanceFilter = () => {
        setShowAdvanceFilterModal(true);
    };


    const handleTagCloseDialog = (files?: ILitigationCaseFiles[]) => {
        console.log(JSON.stringify(files))
        setShowTagModal(false)
    }

    const handleReviewerCloseDialog = (files?: ILitigationCaseFiles[]) => {
        console.log(JSON.stringify(files))
        setShowReviewerModal(false)
    }

    //
    const handleAdvanceFilterCloseDialog = (files?: ILitigationCaseFiles[]) => {
        console.log(JSON.stringify(files))
        setShowAdvanceFilterModal(false)
    }

    const SearchExecution = (pageNumber: number, advanceFilterText: string, IsSearchClear = false, isAdanceFilterClear = false, currentItemsPerPage?: number) => {
        Cases.map((item) => {
            let searchQuery = GetSearchQuery(item.caseId, "", false);
            item.filter = searchQuery;
        });
        //const searchQuery = GetSearchQuery(pageNumber, advanceFilterText, IsSearchClear, isAdanceFilterClear);
        const litigationCaseFilesrequest: ILitigationCaseFilesRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            cases: Cases,
            filter: "",
            searchText: IsSearchClear ? "" : searchText,
            isKeywordSearch: true,
            take: currentItemsPerPage || itemsPerPage,
            skip: pageNumber == 0 ? 0 : pageNumber * itemsPerPage
        }
        dispatch(requestLitigationCaseFiles(litigationCaseFilesrequest));
    }

    const GetSearchQuery = (caseId: string, advanceFilterText: string, IsSearchClear = false, isAdanceFilterClear = false) => {
        let searchQuery = "";
        // Filter items where the attribute is true
        const filteredItems = CaseData.filter(item => item.isChecked && item.caseId == caseId);

        // Map filtered items to their names (or any other property)
        const itemNames = filteredItems.map(item => item.custodian);

        // Join the names into a comma-separated string
        const result = itemNames.join(', ');

        const anyCaseSelected = CaseData.some((item) => item.isChecked);

        if ((searchText?.length == 0 || IsSearchClear) && advanceFilterText?.length == 0 && !anyCaseSelected && advancefilters?.length == 0) {
            if (!litigationChatFolder) {
                litigationCaseFilesResponses.litigationCaseFiles = [];
                litigationCaseFilesResponses.totalRecords = 0;
                setCaseFiles([]);
            }
        } else {

            if (filteredItems.length > 0) {
                //searchQuery = " search.ismatchscoring('" + result + "', 'metadata_storage_path')";
                if (selectedView === "default" || selectedView === "cases") {
                    searchQuery = " search.in(Export_ID,'" + result + "')";
                } else if (selectedView === "Custodian") {
                    searchQuery = "(";
                    searchQuery += filteredItems.map(item => `Custodian eq '${item.custodian}'`).join(' or ');
                    searchQuery += ")";
                }
            }

            if (advanceFilterText?.length > 0 || isAdanceFilterClear) {
                if (!isAdanceFilterClear) {
                    searchQuery = searchQuery?.length > 0 ? searchQuery + " and" + advanceFilterText : advanceFilterText;
                }
            } else if (advancefilters?.length > 0) {
                let generateAdvanceFiter: string = '';
                let groupedFilters = {};

                // Group filters by groupName
                if (Array.isArray(advancefilters)) {
                    advancefilters?.forEach((filter) => {
                        if (filter.fieldKey && filter.operator && filter.fieldValue) {
                            if (!groupedFilters[filter.groupName]) {
                                groupedFilters[filter.groupName] = [];
                            }
                            groupedFilters[filter.groupName].push(filter);
                        }
                    });
                }

                let isFirstGroup = true;

                // Iterate over each group and construct the filter text
                for (let groupName in groupedFilters) {
                    let groupFilterText = groupedFilters[groupName][0]?.condition?.length > 0
                        ? groupedFilters[groupName][0]?.condition + ' (' : ' (';

                    groupedFilters[groupName].forEach((filter, index) => {
                        if (filter.condition.length > 0 && index > 0) {
                            groupFilterText += ' ' + filter.condition;
                        }
                        if (filter.operator.toLowerCase() === 'contains' && filter.fieldValue.trim() !== '') {
                            groupFilterText += ` search.ismatch('${filter.fieldValue}', '${filter.fieldKey}','full','all')`;
                        } else if (filter.operator.toLowerCase() === 'in' || filter.operator.toLowerCase() === 'not in') {
                            if (filter.fieldKey === 'ALL_CUSTODIANS') {
                                // Use contains-like search with search.ismatch for ALL_CUSTODIANS
                                const formattedFieldValue = filter.fieldValue
                                    .split(';') // Split by semicolon
                                    .map(value => value.trim()) // Trim extra spaces
                                    .map(value => `search.ismatch('${value}', 'ALL_CUSTODIANS','full','all')`) // Use contains-like search
                                    .join(' or '); // Join with 'or' for each custodian

                                groupFilterText += filter.operator.toLowerCase() === 'in' ?
                                    `(${formattedFieldValue})` : `not (${formattedFieldValue})`;

                            }
                            else if (filter.fieldKey === 'User_tags') {
                                const formattedFieldValue = filter.fieldValue
                                    .split(';') // Split by semicolon
                                    .map(value => value.trim()) // Trim extra spaces
                                    .map(value => `search.ismatch('${value}', '${filter.fieldKey}','full','all')`) // Use contains-like search
                                    .join(' or '); // Join with 'or' for each custodian

                                groupFilterText += filter.operator.toLowerCase() === 'in' ?
                                    `(${formattedFieldValue})` : `not (${formattedFieldValue})`;
                            }
                            else if (filter.fieldKey === 'Custodian' || filter.fieldKey === 'DocumentTheme') {
                                // Regular in and not in conditions for other field keys
                                const formattedFieldValue = filter.fieldValue
                                    .split(';') // Split by semicolon
                                    .map(value => value.trim()) // Trim extra spaces
                                    .map(value => `${filter.fieldKey} eq '${value}'`) // Construct "fieldKey eq 'value'"
                                    .join(' or '); // Join with 'or'

                                groupFilterText += filter.operator.toLowerCase() === 'in' ?
                                    `(${formattedFieldValue})` : `not (${formattedFieldValue})`;
                            }
                            else {
                                groupFilterText += filter.operator.toLowerCase() === 'in' ?
                                    ` search.in(${filter.fieldKey}, '${filter.fieldValue}',',')` :
                                    ` not search.in(${filter.fieldKey}, '${filter.fieldValue}',',')`;

                            }
                        }
                        else if (filter.type === 'number') {
                            groupFilterText += ` ${filter.fieldKey} ${filter.operator} ${filter.fieldValue}`;
                        }
                        else {
                            if (filter.type === 'date') {
                                groupFilterText += ` ${filter.fieldKey} ${filter.operator} ${filter.fieldValue.toISOString()}`;
                            }
                            else {

                                groupFilterText += ` ${filter.fieldKey} ${filter.operator} '${filter.fieldValue}'`;
                            }
                        }
                        if (isFirstGroup && index === 0) {
                            groupFilterText = groupFilterText.replace(filter.condition, '');
                        }
                    });
                    groupFilterText += ') ';
                    generateAdvanceFiter += groupFilterText;

                    isFirstGroup = false;
                }

                generateAdvanceFiter.replace(/\band$/, '');
                generateAdvanceFiter.replace(/\bor$/, '');

                searchQuery = searchQuery?.length > 0 ? searchQuery + " and" + generateAdvanceFiter : generateAdvanceFiter;

            }
            return searchQuery;

        }
    }

    const DownloadFiles = () => {
        Cases.map((item) => {
            let searchQuery = GetSearchQuery(item.caseId, "", false);
            if (selectedFiles?.length > 0) {
                searchQuery = " search.in(id,'" + selectedFiles.join(',') + "', ',')";
            }
            item.filter = searchQuery;
        });
        const litigationCaseFilesrequest: ILitigationCaseFilesRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            cases: Cases,
            filter: "",
            searchText: searchText,
            isKeywordSearch: true,
            take: litigationCaseFilesResponses.totalRecords,
            skip: 0
        }
        dispatch(requestDownloadLitigationCaseFiles(litigationCaseFilesrequest));

    }

    const onPageChange = (page: number) => {
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        setPageIndex(page - 1); // Pagination component starts indexing from 1, so we need to adjust
        SearchExecution(page - 1, "");
    };

    // const getPageItems = () => {
    //     const startIndex = pageIndex * itemsPerPage;
    //     return CaseFiles.slice(startIndex, startIndex + itemsPerPage);
    // };


    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event?.target) {
            setSearchText(event?.target?.value);
            addSearchText(event?.target?.value);
            if (event?.target?.value == '') {
                setSearchText('');
                addSearchText('');
                setCurrentlySelectedRows([])
                setSelectedItems(0);
                addSelectedFiles([]);
                setPageIndex(0);
                SearchExecution(0, "", true);
            }
        }
    };

    const handleSearchTextClear = () => {
        setSearchText('');
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        setPageIndex(0);
        SearchExecution(0, "", true);
    };
    const fileSearchAction = (searchValue: string) => {
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        setPageIndex(0);
        SearchExecution(0, "");
    };

    const ClearFilesOnFilter = (searchFilter: string) => {
        setPageIndex(0);
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        SearchExecution(0, searchFilter);
    };

    const RefreshSearch = () => {
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        setPageIndex(0);
        SearchExecution(0, "", false, true);
    };

    const UserPreferenceSearch = async (userSearchText, userViewKey, userFolders) => {
        setCurrentlySelectedRows([])
        setSelectedItems(0);
        addSelectedFiles([]);
        setPageIndex(0);
        setselectedViewKey(userViewKey);

        const matchingViewType = viewTypes.find(viewType => viewType.key === userViewKey);

        // Get the id if the view type is found
        const viewTypeId = matchingViewType ? matchingViewType.id : null;

        const litigationCaseDataRequest: ILitigationCaseDataRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            cases: Cases,
            viewType: viewTypeId as string
        }

        await dispatch(requestLitigationCaseData(litigationCaseDataRequest));

        setUserFolders(userFolders);
        setSearchText(userSearchText);
        if (!userFolders) {
            SearchExecution(0, '', false, false);
        }

        // After dispatch is complete, handle the userFolders logic
        // if (userFolders === 'All') {

        //     CaseData?.forEach((item) => { item.isChecked = true; });
        // } else if (userFolders && userFolders.length > 0) {

        //     const foldersArray = userFolders.split(',');
        //     foldersArray.forEach((folder) => {
        //         const caseDataItem = CaseData.find((x) => x.custodian === folder);
        //         if (caseDataItem) {
        //             caseDataItem.isChecked = true;
        //         }
        //     });
        // }


    };

    const handleCloseChat = () => {
        setshowChat(false);
    };

    const onFolderClickLitigationChatbot = (ids: string[]) => {
        //Update cases here
        setPreviousCaseFiles(CaseFiles);
        setLitigationChatFolder(true);
        setLoadCasesIdsOnFolderClick(ids);
        console.log("Folder Clicked, IDs:", ids);
        const searchFilter = `search.in(id, '${ids.join(',')}', ',')`;

        // Dispatch an action to fetch case files with the search filter
        const litigationCaseFilesRequest: ILitigationCaseFilesRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            cases: Cases.map(caseItem => ({
                ...caseItem,
                filter: searchFilter
            })),
            filter: searchFilter,
            searchText: '',
            isKeywordSearch: true,
            take: itemsPerPage,
            skip: 0
        };

        setDisableFolderButton(true);
        dispatch(requestLitigationCaseFiles(litigationCaseFilesRequest));
    }

    React.useEffect(() => {
        if (userFolders === 'All') {
            setCheckboxAllChecked(true);
            setCaseData(prevCaseData => prevCaseData.map(item => ({ ...item, isChecked: true })));
        } else if (userFolders && userFolders.length > 0) {
            setCheckboxAllChecked(false);
            const foldersArray = userFolders.split(',');
            setCaseData(prevCaseData => prevCaseData.map(item => ({
                ...item,
                isChecked: foldersArray.includes(item.custodian)
            })));
        }
        SearchExecution(0, '', false, false);
    }, [litigationCaseDataResponses, userFolders]);

    function onChatReferenceXClick(): void {
        setLitigationChatFolder(false);
        setCaseFiles(previousCaseFiles);
        setTotalRecords(previousTotalRecords); // Set totalRecords to previousTotalRecords
        console.log("Reverting to previous case files:", previousCaseFiles);
        setDisableFolderButton(false);
    }

    const chatWidth = window.innerWidth > 2400
        ? (isChatExpanded ? 900 : 800)
        : (isChatExpanded ? 600 : 450);

    const adjustedChatWidth = chatWidth + 200;
    // Set the stack width based on showLitigationChatModal
    const stackWidth = isChatExpanded
        ? '100%'
        : (showLitigationChatModal ? `calc(100% - ${chatWidth + 200}px)` : '100%');

    const handleItemsPerPageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            const newItemsPerPage = option.key as number;

            // Update the itemsPerPage state
            setItemsPerPage(newItemsPerPage);

            // Reset to the first page
            setPageIndex(0);
            setCurrentlySelectedRows([]);
            setSelectedItems(0);
            addSelectedFiles([]);

            // Execute the search for the first page with the updated itemsPerPage
            SearchExecution(0, "", false, false, newItemsPerPage);
        }
    };

    return (
        <>
            <div style={{ marginLeft: 20, maxHeight: window.innerHeight - 150 }} className="pageContainer">
                {showTagModal && (
                    <TagContentDialog
                        isOpen={showTagModal}
                        title='Add tags to selected files'
                        onDismiss={handleTagCloseDialog}
                        dialogWidth="670px"
                        dialogHeight="500px"
                        files={selectedCaseFiles}
                        refreshCaseDetailsView={refreshCaseDetailsView}
                        cases={Cases}
                    />
                )}

                {showReviewerModal && (
                    <ReviewerContentDialog
                        isOpen={showReviewerModal}
                        title='Assign reviewers to selected files'
                        onDismiss={handleReviewerCloseDialog}
                        dialogWidth="600px"
                        dialogHeight="400px"
                        files={currentlySelectedRows}
                    />
                )}

                {showAdvanceFilterModal && (
                    <AdvanceFilter
                        ClearFilesOnFilter={ClearFilesOnFilter}
                        isOpen={showAdvanceFilterModal}
                        title='Advance Filter'
                        onDismiss={handleAdvanceFilterCloseDialog}
                        dialogWidth="900px"
                        dialogHeight="500px"
                    />
                )}

                <div className="contentContainer">

                    <Stack>
                        <Stack>
                            <Stack>
                                {!isLoadingSaveUserQuery && !isSaveUserQueryError && isSaveUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"User preference saved successfully"}
                                            messageType={MessageBarType.success}
                                            message={"User preference saved successfully"}
                                        />
                                    </>
                                )}
                                {!isLoadingUpdateUserQuery && !isUpdateUserQueryError && isUpdateUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"User preference updated successfully"}
                                            messageType={MessageBarType.success}
                                            message={"User preference updated successfully"}
                                        />
                                    </>
                                )}
                                {!isLoadingDeleteUserQuery && !isDeleteUserQueryError && isDeleteUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"User preference deleted successfully"}
                                            messageType={MessageBarType.success}
                                            message={"User preference deleted successfully"}
                                        />
                                    </>
                                )}
                                {!isLoadingSaveUserQuery && isSaveUserQueryError && !isSaveUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"Error occured while saving user preference"}
                                            messageType={MessageBarType.error}
                                            message={"Error occured while saving user preference"}
                                        />
                                    </>
                                )}
                                {!isLoadingUpdateUserQuery && isUpdateUserQueryError && !isUpdateUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"Error occured while updating user preference"}
                                            messageType={MessageBarType.error}
                                            message={"Error occured while updating user preference"}
                                        />
                                    </>
                                )}
                                {!isLoadingDeleteUserQuery && isDeleteUserQueryError && !isDeleteUserQuerySuccess && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"Error occured while deleting user preference"}
                                            messageType={MessageBarType.error}
                                            message={"Error occured while deleting user preference"}
                                        />
                                    </>
                                )}
                                {!isLitigationCaseResultsLoading && showSaveTagSuccessMessage && (
                                    <>
                                        <DisplayMessage
                                            accessabilityMessage={"File tags saved successfully"}
                                            messageType={MessageBarType.success}
                                            message={"File tags saved successfully"}
                                        />
                                    </>
                                )}
                                <ClassificationStatus showFileClassificationSuccessMessage={showFileClassificationSuccessMessage} />

                                {!isLitigationCaseResultsLoading && !isFilesClassificationLoading && isFilesClassificationError && (
                                    <>
                                        <ErrorDisplay source={"Error during file classification"} error={litigationClassifyFileResponseError} />
                                    </>
                                )}
                                {!isLitigationCaseResultsLoading && isLitigationCaseResultsLoadingError && (
                                    <>
                                        <ErrorDisplay source={"Error in loading Case folders"} error={litigationCaseResponsesError} />
                                    </>
                                )}
                                {!isLitigationCaseDataResultsLoading && isLitigationCaseDataResultsLoadingError && (
                                    <>
                                        <ErrorDisplay source={"Error in loading Case folders"} error={litigationCaseDataResponsesError} />
                                    </>
                                )}
                                {!isLitigationCaseFilesResultsLoading && isLitigationCaseFilesResultsLoadingError && (
                                    <>
                                        <ErrorDisplay source={"Error in loading dDocuments to review"} error={litigationCaseFilesResponsesError} />
                                    </>
                                )}
                                <Stack style={{ marginTop: '15px' }}>
                                    <Stack horizontal tokens={{ childrenGap: 15 }} style={{ marginTop: '5px' }} >
                                        {!projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <button
                                                id="advance-filter-btn"
                                                onClick={handleAdvanceFilter}
                                                disabled={litigationChatFolder}
                                                style={{ minWidth: 125 }}
                                                className={advancefilters?.length > 0 ? "advanceFilter-btn" : ""}>
                                                <div style={{ fontWeight: "600" }}>
                                                    {advancefilters?.length == 0 &&
                                                        (
                                                            <Icon iconName={'Filter'} style={{ marginRight: '5px' }} />
                                                        )}
                                                    {advancefilters?.length > 0 &&
                                                        (
                                                            <Icon iconName={'ClearFilter'} style={{ marginRight: '5px' }} />
                                                        )}
                                                    Advance filters
                                                </div>
                                            </button>
                                        )}
                                        {projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <button
                                                id="advance-filter-btn"
                                                onClick={handleAdvanceFilter}
                                                disabled={litigationChatFolder}
                                                style={{ minWidth: 125 }}
                                                className={advancefilters?.length > 0 ? "advanceFilter-btn" : ""}>
                                                <div style={{ fontWeight: "600" }}>

                                                    <Icon iconName={'Timeline'} style={{ marginRight: '5px' }} />
                                                    Generate Timelines
                                                </div>
                                            </button>
                                        )}
                                        {projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <button
                                                id="advance-filter-btn"
                                                onClick={handleAdvanceFilter}
                                                disabled={litigationChatFolder}
                                                style={{ minWidth: 125 }}
                                                className={advancefilters?.length > 0 ? "advanceFilter-btn" : ""}>
                                                <div style={{ fontWeight: "600" }}>

                                                    <Icon iconName={'TagSolid'} style={{ marginRight: '5px' }} />

                                                    Generate Review Set
                                                </div>
                                            </button>
                                        )}
                                        {!projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <button
                                                //disabled={selectedItems === 0}
                                                disabled={true}
                                                id="add-reviewer-btn"
                                                onClick={addReviewers}
                                                style={{ minWidth: 135 }}
                                            >
                                                <div style={{ fontWeight: "600" }}>
                                                    <Icon iconName={'Contact'} style={{ marginRight: '5px' }} />
                                                    Assign reviewers
                                                </div>
                                            </button>
                                        )}
                                        {!projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <button
                                                disabled={selectedCaseFiles?.length === 0}
                                                id="assign-tags-btn"
                                                onClick={addTags}
                                                style={{ marginRight: '5', minWidth: 125 }}
                                            >
                                                <div style={{ fontWeight: "600" }}>
                                                    <Icon iconName={'Tag'} style={{ marginRight: '5px' }} />
                                                    Add tags
                                                </div>
                                            </button>
                                        )}
                                        {!projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                            <ClassifyFiles files={selectedCaseFiles} gridFiles={CaseFiles}
                                                refreshCaseDetailsView={refreshCaseDetailsViewForFileClassificationSuccess}
                                                cases={Cases} />
                                        )}
                                        <button
                                            disabled={isLitigationCaseFilesResultsLoading || litigationChatFolder || isLitigationCaseFilesResultsLoadingError || !(litigationCaseFilesResponses?.litigationCaseFiles?.length > 0)}
                                            id="download-btn"
                                            onClick={() => {
                                                // Only allow the click if the condition to disable the icon is not met
                                                if (!isLitigationCaseFilesResultsLoading && !isLitigationCaseFilesResultsLoadingError && litigationCaseFilesResponses?.litigationCaseFiles?.length > 0) {
                                                    DownloadFiles();
                                                }
                                            }}
                                            style={{
                                                cursor: !isLitigationCaseFilesResultsLoading && !isLitigationCaseFilesResultsLoadingError && litigationCaseFilesResponses?.litigationCaseFiles?.length > 0 ? 'pointer' : 'not-allowed',
                                                opacity: !isLitigationCaseFilesResultsLoading && !isLitigationCaseFilesResultsLoadingError && litigationCaseFilesResponses?.litigationCaseFiles?.length > 0 ? 1 : 0.5,
                                                marginRight: '5px', minWidth: 100

                                            }}
                                        >
                                            <div style={{ fontWeight: "600" }}>
                                                <Icon iconName={'Export'} style={{
                                                    marginRight: '5px', verticalAlign: 'middle'
                                                }} />
                                                Export
                                            </div>
                                        </button>
                                        <SearchBox
                                            styles={{ root: { width: 'calc(100% - 715px)', minWidth: 250 } }}
                                            onEscape={handleSearchTextClear}
                                            onClear={handleSearchTextClear}
                                            ref={focusSearch}
                                            showIcon={true}
                                            ariaLabel="Enter search text to search files"
                                            placeholder="Enter search text to search files."
                                            onSearch={(newValue) => fileSearchAction(newValue)}
                                            onChange={handleSearchTextChange}
                                            value={searchText}
                                        />
                                        <div style={{ pointerEvents: 'auto', marginRight: -25 }}>
                                            {/* Copilot Icon */}
                                            {!showLitigationChatModal && (
                                                <img
                                                    src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                                    alt="CoPilot Icon"
                                                    onClick={handleOpenLitigationChatModal}
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 24,
                                                        height: 24,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Stack>
                                </Stack>
                            </Stack >
                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 14 }} style={{ marginTop: '10px' }}>
                            {!projectProps?.workArea?.toLowerCase().includes('nvlsp') && (
                                <Stack style={{ marginRight: '10px' }}>


                                    <Stack style={
                                        {
                                            backgroundColor: 'white',
                                            height: 'calc(100vh - 200px)',
                                            width: isExpanded ? '440px' : '220px',
                                            overflowY: 'auto',
                                            overflowX: isExpanded ? 'hidden' : 'auto',
                                            whiteSpace: 'nowrap',
                                            position: isExpanded ? 'absolute' : 'static',
                                            transition: 'width 0.3s ease-in-out',
                                            zIndex: 999
                                        }} >
                                        {isLitigationCaseDataResultsLoading && (
                                            <Stack style={{ marginTop: 0 }}>
                                                <span role="alert" aria-label={GenericMessages.dueDateProjectsLoading} aria-live="assertive" />
                                                <DisplaySpinner
                                                    accessabilityMessage={"Loading Case Folders"}
                                                    spinnerText={"Loading Case Folders..."}
                                                    spinnerPosition={"right"}
                                                />
                                            </Stack>
                                        )}
                                        {!isLitigationCaseDataResultsLoading && !isLitigationCaseDataResultsLoadingError && litigationCaseDataResponses && litigationCaseDataResponses?.litigationCaseDataResponses?.length > 0 && (
                                            <>
                                                <Tree aria-label="Browse folders"
                                                    style={{ width: '100%', maxWidth: '100%', marginTop: '7px', marginLeft: 10, marginRight: 10, }}>
                                                    <Stack style={{ fontWeight: "600" }}>
                                                        <Stack horizontal style={{ marginTop: -5 }}>
                                                            <Checkbox
                                                                styles={{
                                                                    root: {
                                                                        height: '16px',
                                                                        width: '16px',
                                                                        marginTop: '5px',
                                                                        marginRight: '10px'
                                                                    }
                                                                }}
                                                                id={'checkboxAll'}
                                                                onChange={handleCheckboxAllChange}
                                                                checked={checkboxAllChecked}
                                                                indeterminate={CaseData?.some((val) => val.isChecked) && !checkboxAllChecked}
                                                                disabled={litigationChatFolder}
                                                            />
                                                            <span style={{ height: '16px', marginTop: '5px', marginRight: '10px' }}>All Folders</span>
                                                            <IconButton
                                                                iconProps={{ iconName: isExpanded ? 'CollapseContentSingle' : 'MiniExpand' }}
                                                                onClick={toggleExpand}
                                                                style={{ fontSize: '12px', marginLeft: 'auto', color: 'black', marginRight: 5 }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                    <Stack style={{ marginTop: '7px', maxWidth: '90%' }}>
                                                        <Dropdown
                                                            defaultSelectedKey={defaultViewTypeKey}
                                                            title="Select view"
                                                            placeholder="Select view"
                                                            selectedKey={selectedViewKey}
                                                            options={viewTypes}
                                                            onChange={(event, item) => {
                                                                onViewTypeChange(event, item);
                                                            }}
                                                        />
                                                    </Stack>
                                                    <Stack style={{ marginTop: 7 }}>
                                                        {CaseData?.map((item, counter) => (
                                                            <>
                                                                <Stack horizontal style={{ marginTop: -5 }}>
                                                                    <Checkbox
                                                                        styles={{
                                                                            root: {
                                                                                height: '16px',
                                                                                width: '16px',
                                                                                marginTop: '5px',
                                                                                marginRight: '10px'
                                                                            }
                                                                        }}
                                                                        id={'checkbox' + item?.custodian + item?.caseId}
                                                                        onChange={handleCheckboxChange(item)}
                                                                        checked={item.isChecked}
                                                                        disabled={litigationChatFolder}
                                                                    />
                                                                    <TreeItemLayout
                                                                        iconBefore={<Folder20Regular />}
                                                                    >
                                                                        <TooltipHost content={item.custodian}>
                                                                            {item.custodian}
                                                                        </TooltipHost>

                                                                    </TreeItemLayout>
                                                                </Stack>
                                                            </>
                                                        ))}
                                                    </Stack>
                                                </Tree>
                                            </>
                                        )}</Stack>

                                </Stack>
                            )}
                            <Stack style={{
                                overflow: 'auto',
                                width: stackWidth,
                                minWidth: 300
                            }}>
                                {!litigationChatFolder && <CaseFilesDetailList Cases={Cases} CaseFiles={CaseFiles} totalRecords={totalRecords}
                                    isAdvanceFilterApplied={advancefilters?.length > 0} viewType={selectedView} RefreshSearch={RefreshSearch} UserPreferenceSearch={UserPreferenceSearch} isLitigationChatFolderButtonClicked={litigationChatFolder} />}
                                {litigationChatFolder &&
                                    <CaseFilesDetailList
                                        Cases={Cases}
                                        CaseFiles={litigationCaseFilesResponses?.litigationCaseFiles || []}
                                        totalRecords={totalRecords}
                                        isAdvanceFilterApplied={advancefilters?.length > 0}
                                        viewType={selectedView}
                                        RefreshSearch={RefreshSearch}
                                        UserPreferenceSearch={UserPreferenceSearch}
                                        isLitigationChatFolderButtonClicked={litigationChatFolder}
                                        onChatReferenceXClick={onChatReferenceXClick}

                                    />
                                }
                            </Stack>
                            <Stack>
                                <div className={`case-copilot-container ${isChatExpanded ? ' expanded' : ''}`} style={{ position: 'fixed', right: '10px', zIndex: 1000, pointerEvents: 'none' }}>
                                    <Stack>

                                        {/* Chat Window */}
                                        {showLitigationChatModal && (
                                            <Stack className="copilot-chat-window" style={{
                                                position: 'fixed',
                                                right: '10px',
                                                zIndex: 1000,
                                                backgroundColor: 'rgba(245, 245, 245, 0.9)',
                                                backdropFilter: 'blur(10px)',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                pointerEvents: 'auto',
                                                width: window.innerWidth > 2400 ? (isChatExpanded ? '900px' : '800px') : (isChatExpanded ? '600px' : '450px'), // Increase width by 175px if expanded
                                                maxWidth: '90vw',
                                                overflow: 'auto',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Stack horizontal>
                                                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginLeft: 10 } }}>
                                                        <img
                                                            src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                                            alt="CoPilot Icon"
                                                            onClick={handleOpenLitigationChatModal}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,
                                                            }}
                                                        />
                                                        <Stack className="copilot-chat-label">Legal Ease AI Assistant</Stack>
                                                    </Stack>
                                                    <Stack horizontal style={{ marginLeft: 'auto', alignItems: 'center' }}>
                                                        <IconButton
                                                            iconProps={{ iconName: isChatExpanded ? 'CollapseContentSingle' : 'MiniExpand' }}
                                                            onClick={toggleChatExpand}
                                                            style={{ fontSize: '12px', color: 'black', marginRight: 5 }}
                                                        />
                                                        <span tabIndex={0} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={handleOpenLitigationChatModal}>X</span>
                                                    </Stack>
                                                </Stack>
                                                <div>
                                                    <ChatHome cases={Cases} files={selectedCaseFiles} user={loginUserpersonaProps} projectNumber={projectContext?.number} isChatExpanded={isChatExpanded} onFolderClick={onFolderClickLitigationChatbot} isCasePage={true} disableFolderButton={disableFolderButton} />
                                                </div>
                                            </Stack>
                                        )}

                                    </Stack>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                <div className="buttonContainer">
                    <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 'auto', paddingLeft: '35%' }}>

                        <Stack style={{ marginTop: '15px' }}>
                            {!isLitigationCaseFilesResultsLoading && !isLitigationCaseFilesResultsLoadingError && litigationCaseFilesResponses && litigationCaseFilesResponses?.litigationCaseFiles?.length > 0 && (
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Pagination
                                        selectedPage={pageIndex + 1} // Pagination component expects current page to start from 1
                                        pageCount={Math.ceil(totalRecords / itemsPerPage)}
                                        onPageChange={onPageChange}
                                    />
                                    {totalRecords > 50 && (
                                        <Dropdown
                                            options={options}
                                            selectedKey={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                            styles={{ dropdown: { width: 120 } }}
                                            ariaLabel="Select items per page" // Add an ARIA label for screen readers
                                            placeholder="Choose an option" // Provide a placeholder for clarity
                                        />
                                    )}

                                </Stack>

                            )}
                        </Stack>

                    </Stack>
                </div>
            </div >
        </>
    );
}
export default FileManagement;

import { SimpleEffect, getContext, put, all, call, takeLatest, select } from 'redux-saga/effects';
import { IHttpClient } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';
import { trackProjectException, trackProjectEvents, getProjectStateBusinessProcessProperties } from '../../Helpers/ProjectTelemtry';
import { ChatActions } from '../../Helpers/ChatConstants';
import { ChatActionTypes, IRequestDeleteChatTopic, IRequestEditChatTopic, IRequestGetChatTopic, IRequestNewAnswer, IRequestSystemPrompts, IRequestUserChatLogs } from '../Actions/Chat.action-types';
import { ITopic, ITopicResponses } from '../../Models/Chat/ITopicResponses';
import { responseDefaultNewAnswer, responseDeleteChatTopic, responseEditChatTopic, responseErrorDeleteChatTopic, responseErrorEditChatTopic, responseErrorGetChatTopic, responseErrorNewAnswer, responseErrorUserChatLogs, responseGetChatTopic, responseNewAnswer, responseSystemPrompts, responseUserChatLogs } from '../Actions/Chat.action';
import { ChatFailureEventId, ChatSuccessEventId } from '../../Helpers/ChatEnums';
import { ChatAPI } from '../../Services/ChatAPI';

import { ISystemPrompts, ISystemPromptsList } from '../../Models/Chat/ISystemPrompts';

import { IUserChatLogsResponse } from '../../Models/Chat/IUserChatLogsResponse';

import { IChatTopicDeleteResponse } from '../../Models/Chat/IChatTopicDeleteResponse';
import { IChatTopicEditResponse } from '../../Models/Chat/IChatTopicEditResponse';
import { IQuestion } from '../../Models/Chat/IQuestion';
import { IAuthClient } from '@micro-frontend-react/employee-experience/lib/IAuthClient';


const logException = (telemetryClient: ITelemetryClient, appAction: string, stateCommonProperties: any, exception: any, inputData: any, eventId: number) => {
	trackProjectException(telemetryClient, 'CELAEngageApp', appAction, stateCommonProperties, exception, eventId, appAction + ':Failure', inputData);
};

const logSuccess = (telemetryClient: ITelemetryClient, appAction: string, stateCommonProperties: any, inputData: any, eventId: number) => {
	trackProjectEvents(telemetryClient, 'CELAEngageApp', appAction, stateCommonProperties, eventId, appAction + ':Success', inputData);
};

const createSystemPromptsList = (questions: string[]): ISystemPromptsList => {
  const systemPrompts = questions.map((question) => ({
    question,
    prompt: question
  }));

  return { systemPrompts };
};

function* requestNewAnswer(action: IRequestNewAnswer): IterableIterator<SimpleEffect<{}, {}>> {
	const authClient: IAuthClient = yield getContext("authClient");
	
	const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
	const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
	const correlationId = stateCommonProperties?.correlationId;
	let appAction: string = ChatActions.RequestNewAnswerAction;
	try {
		const httpClient: IHttpClient = yield getContext('httpClient');
		const topic: ITopic = yield call(ChatAPI.requestAnswer, action, httpClient, correlationId, stateCommonProperties,authClient);

		yield put(responseNewAnswer(topic));
		
		logSuccess(telemetryClient, appAction, stateCommonProperties, action?.chatQuestionRequest, ChatSuccessEventId.ChatQuestionRequestSuccess);
	} catch (errorResponse: any) {
		const error = errorResponse?.data ?? errorResponse;
		const exception = error.data ? new Error(error.data) : error;
		yield put(responseErrorNewAnswer(exception, errorResponse, correlationId));
		logException(telemetryClient, appAction, stateCommonProperties, exception, action?.chatQuestionRequest, ChatFailureEventId.ChatQuestionRequestFailure);
	}
}

function* requestDefaultNewAnswer(action: IRequestNewAnswer): IterableIterator<SimpleEffect<{}, {}>> {
	const authClient: IAuthClient = yield getContext("authClient");
	const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
	const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
	const correlationId = stateCommonProperties?.correlationId;
	let appAction: string = ChatActions.RequestNewAnswerAction;
	try {
		const httpClient: IHttpClient = yield getContext('httpClient');
		const apiResponse: any = yield call(ChatAPI.requestDefaultAnswer, action, httpClient, correlationId, stateCommonProperties,authClient);
		
		// Check if response is valid and contains the expected structure
		const questionsList = apiResponse?.questions?.[0]?.searchResponsesList?.[0];
		let systemPromptsList: ISystemPromptsList = { systemPrompts: [] };

		// Process the answer if available and in the expected format
		if (questionsList?.answer) {
			const answer = questionsList.answer;
			const extractedQuestions = extractQuestionsFromAnswer(answer);
			systemPromptsList = createSystemPromptsList(extractedQuestions);
		}

		// Dispatch the systemPromptsList (empty if answer was missing or invalid)
		yield put(responseDefaultNewAnswer(systemPromptsList));

		// Log success
		logSuccess(telemetryClient, appAction, stateCommonProperties, action?.chatQuestionRequest, ChatSuccessEventId.ChatQuestionRequestSuccess);
	} catch (errorResponse: any) {
		const error = errorResponse?.data ?? errorResponse;
		const exception = error.data ? new Error(error.data) : error;

		// Handle error and log exception
		yield put(responseErrorNewAnswer(exception, errorResponse, correlationId));
		logException(telemetryClient, appAction, stateCommonProperties, exception, action?.chatQuestionRequest, ChatFailureEventId.ChatQuestionRequestFailure);
	}
}


function extractQuestionsFromAnswer(answer: string): string[] {
  return answer.split(/\d+\.\s+/).filter(Boolean);
}

function* requestSystemPrompts(action: IRequestSystemPrompts): IterableIterator<SimpleEffect<{}, {}>> {
  const authClient: IAuthClient = yield getContext("authClient");
  const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
  const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
  const correlationId = stateCommonProperties?.correlationId;
  let appAction: string = ChatActions.RequestSystemPromptsAction;
  try {
    const httpClient: IHttpClient = yield getContext("httpClient");
    const systemPrompts: ISystemPrompts[] = yield call(
      ChatAPI.requestSystemPrompts,
      action,
      httpClient,
      correlationId,authClient
    );
    const systemPromptsList: ISystemPromptsList = { systemPrompts };
    yield put(responseSystemPrompts(systemPromptsList));
    logSuccess(
      telemetryClient,
      appAction,
      stateCommonProperties,
      "RequestSystemPrompts",
      ChatSuccessEventId.RequestSystemPromptsAction_Success
    );
  } catch (errorResponse: any) {
    const error = errorResponse?.data ?? errorResponse;
    const exception = error.data ? new Error(error.data) : error;
    yield put(responseErrorNewAnswer(exception, errorResponse, correlationId));
    logException(
      telemetryClient,
      appAction,
      stateCommonProperties,
      exception,
      "RequestSystemPrompts",
      ChatFailureEventId.RequestSystemPromptsAction_Failure
    );
  }
}

function* requestChatLogs(action: IRequestUserChatLogs): IterableIterator<SimpleEffect<{}, {}>> {
  const authClient: IAuthClient = yield getContext("authClient");
  const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
  const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
  const correlationId = stateCommonProperties?.correlationId;
  let appAction: string = ChatActions.RequestChatLogsAction;
  try {
    const httpClient: IHttpClient = yield getContext("httpClient");
    const userChatLogsResponse: IUserChatLogsResponse[] = yield call(
      ChatAPI.requestChatLogs,
      action,
      httpClient,
      correlationId,authClient
    );

    yield put(responseUserChatLogs(userChatLogsResponse));
    logSuccess(
      telemetryClient,
      appAction,
      stateCommonProperties,
      action?.userChatLogsRequest,
      ChatSuccessEventId.RequestChatLogs_Success
    );
  } catch (errorResponse: any) {
    const error = errorResponse?.data ?? errorResponse;
    const exception = error.data ? new Error(error.data) : error;
    yield put(responseErrorUserChatLogs(exception, errorResponse, correlationId));
    logException(
      telemetryClient,
      appAction,
      stateCommonProperties,
      exception,
      action?.userChatLogsRequest,
      ChatFailureEventId.RequestChatLogs_Failure
    );
  }
}

function* requestDeleteChatTopic(action: IRequestDeleteChatTopic): IterableIterator<SimpleEffect<{}, {}>> {
  const authClient: IAuthClient = yield getContext("authClient");
  const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
  const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
  const correlationId = stateCommonProperties?.correlationId;
  let appAction: string = ChatActions.DeleteChatTopicAction;
  try {
    const httpClient: IHttpClient = yield getContext("httpClient");
    yield call(ChatAPI.requestDeleteChatTopic, action, httpClient, correlationId,authClient);
    const chatTopicDeleteResponse: IChatTopicDeleteResponse = {
      isDeleteTopicSuccess: true,
    };
    yield put(responseDeleteChatTopic(chatTopicDeleteResponse));
    logSuccess(
      telemetryClient,
      appAction,
      stateCommonProperties,
      action.chatTopicDeleteRequest,
      ChatSuccessEventId.DeleteChatTopic_Success
    );
  } catch (errorResponse: any) {
    const error = errorResponse?.data ?? errorResponse;
    const exception = error.data ? new Error(error.data) : error;
    yield put(responseErrorDeleteChatTopic(exception, errorResponse, correlationId));
    logException(
      telemetryClient,
      appAction,
      stateCommonProperties,
      exception,
      action.chatTopicDeleteRequest,
      ChatFailureEventId.DeleteChatTopic_Failure
    );
  }
}

function* requestEditChatTopic(action: IRequestEditChatTopic): IterableIterator<SimpleEffect<{}, {}>> {
  const authClient: IAuthClient = yield getContext("authClient");
  const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
  const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
  const correlationId = stateCommonProperties?.correlationId;
  let appAction: string = ChatActions.EditChatTopicAction;
  try {
    const httpClient: IHttpClient = yield getContext("httpClient");
    yield call(ChatAPI.requestEditChatTopic, action, httpClient, correlationId,authClient);
    const chatTopicEditResponse: IChatTopicEditResponse = {
      isEditTopicSuccess: true,
    };
    yield put(responseEditChatTopic(chatTopicEditResponse));
    logSuccess(
      telemetryClient,
      appAction,
      stateCommonProperties,
      action.chatTopicEditRequest,
      ChatSuccessEventId.EditChatTopic_Success
    );
  } catch (errorResponse: any) {
    const error = errorResponse?.data ?? errorResponse;
    const exception = error.data ? new Error(error.data) : error;
    yield put(responseErrorEditChatTopic(exception, errorResponse, correlationId));
    logException(
      telemetryClient,
      appAction,
      stateCommonProperties,
      exception,
      action.chatTopicEditRequest,
      ChatFailureEventId.EditChatTopic_Failure
    );
  }
}

function* requestChatLog(action: IRequestGetChatTopic): IterableIterator<SimpleEffect<{}, {}>> {
  const authClient: IAuthClient = yield getContext("authClient");
	const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
	const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
	const correlationId = stateCommonProperties?.correlationId;
	let appAction: string = ChatActions.RequestChatLogAction;
	try {
		const httpClient: IHttpClient = yield getContext('httpClient');
		const topic: ITopic = yield call(ChatAPI.requestChatLog, action, httpClient, correlationId,authClient);
		let newChatTopicResponses: ITopicResponses = { chatTopicResponses: [] };
		if (topic && Object.keys(topic).length > 0) {
			let question: IQuestion = {};
			topic?.questions.map((dbQuestion: IQuestion, index: number) => {
				let topicQuestion: ITopic = {};
				dbQuestion = sortObjectKeysAscending(dbQuestion);
				Object.keys(dbQuestion).map((key: string, index: number) => {
					question = {};
					topicQuestion = {
						user: '',
						isNewQuestion: false,
						isNewAnswer: false,
						questions: [],
						topicId: topic.topicId,
						topicName: topic.topicName
					};
					if (key === 'searchResponsesList') {
						question.searchResponsesList = dbQuestion.searchResponsesList;
						question.qnaId = dbQuestion?.qnaId;
						//(question.intakeForm = dbQuestion?.intakeForm), (question.shouldDisplayIntakeForm = dbQuestion?.shouldDisplayIntakeForm);
						topicQuestion.questions = topicQuestion.questions.concat(question);
						topicQuestion.user = 'gpt';
						
						newChatTopicResponses.chatTopicResponses = newChatTopicResponses?.chatTopicResponses?.concat(topicQuestion);
					} else if (key === 'qnaId') {
						(question.qnaId = dbQuestion?.qnaId), (question.userQuestion = dbQuestion?.userQuestion), (topicQuestion.questions = topicQuestion.questions.concat(question));
						topicQuestion.user = 'me';
						newChatTopicResponses.chatTopicResponses = newChatTopicResponses?.chatTopicResponses?.concat(topicQuestion);
					}
				});
			});
		}
		//newChatTopicResponses.userAttorneys = topic?.userAttorneys;
		
		newChatTopicResponses.topic = topic;
		newChatTopicResponses.topic.isRead = topic?.isRead
		yield put(responseGetChatTopic(newChatTopicResponses));
		
		logSuccess(telemetryClient, appAction, stateCommonProperties, action?.chatTopicRequest?.topicId, ChatSuccessEventId.RequestChatLog_Success);
	} catch (errorResponse: any) {
		const error = errorResponse?.data ?? errorResponse;
		const exception = error.data ? new Error(error.data) : error;
		yield put(responseErrorGetChatTopic(exception, errorResponse, correlationId));
		logException(telemetryClient, appAction, stateCommonProperties, exception, action?.chatTopicRequest?.topicId, ChatFailureEventId.RequestChatLog_Failure);
	}
}
export function* ChatSagas(): IterableIterator<{}> {
  yield all([takeLatest(ChatActionTypes.NEW_QUESTION_REQUEST, requestNewAnswer)]);
  yield all([takeLatest(ChatActionTypes.NEW_DEFAULT_QUESTION_REQUEST, requestDefaultNewAnswer)]);
	yield all([takeLatest(ChatActionTypes.REQUEST_SYSTEM_PROMPTS, requestSystemPrompts)]);
  yield all([takeLatest(ChatActionTypes.REQUEST_CHAT_LOGS, requestChatLogs)]);
  yield all([takeLatest(ChatActionTypes.REQUEST_DELETE_CHAT_TOPIC, requestDeleteChatTopic)]);
  yield all([takeLatest(ChatActionTypes.REQUEST_EDIT_CHAT_TOPIC, requestEditChatTopic)]);
  yield all([takeLatest(ChatActionTypes.REQUEST_GET_CHAT_TOPIC, requestChatLog)]);
}

const sortObjectKeysAscending = (obj: IQuestion): IQuestion => {
	const sortedKeys = Object.keys(obj).sort();
	const sortedObject: IQuestion = {};

	sortedKeys.forEach(key => {
		sortedObject[key] = obj[key];
	});

	return sortedObject;
};
import React from "react";
import { ChatAPITokens } from "../../Services/ChatAPI";
import { AxiosError } from "axios";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
const useChatAIToken = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);    
  const { authClient } = reduxContext;
  const [isChatAITokenLoading, setIsChatAITokenLoading] = React.useState(false);
  const [chatAIToken, setChatAIToken] = React.useState<string>("");
  const getChatAIAPIToken = async (): Promise<any> => {
    try {
      const chatApiToken = await ChatAPITokens.getChatAPIToken();
      setIsChatAITokenLoading(false);
      return chatApiToken;
    } catch (error: any | AxiosError) {
      setIsChatAITokenLoading(false);
      return "";
    }
  };

  React.useEffect(() => {
    setIsChatAITokenLoading(true);
    const fetchChatToken = async () => {
      try {
        const lmiToken = await getChatAIAPIToken();
        setChatAIToken(lmiToken);
      } catch (error: any) {
        console.log(error);
      }
    };
    fetchChatToken();
  }, []);

  return { isChatAITokenLoading, chatAIToken };
};
export default useChatAIToken;




import { IButtonStyles, IStyle, mergeStyleSets } from '@fluentui/react';

const linkStyles: IStyle = {
    background: 'none',
    border: 'none',
    color: '#0078d4', // Fluent UI primary link color
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
    selectors: {
        ':hover': {
            color: '#005a9e', // Darker shade for hover effect
            textDecoration: 'none', // Optional: remove underline on hover
        },
    },
};

const buttonStyles: IButtonStyles = {
    root: { backgroundColor: "#6264A7", borderColor: "#6264A7" },
    rootHovered: { backgroundColor: "#8B8CC7" },
    rootPressed: { backgroundColor: "#5558A3" },
};

export const classificationConfirmationStyles = mergeStyleSets({
    link: linkStyles,
    preFormat: {
        whiteSpace: 'pre-wrap', wordWrap: 'break-word'
    },
    primaryButtonStyles: buttonStyles
});

import { IColumn } from "@fluentui/react"

export const FileHistoryColumns: IColumn[] = [
    {
        key: 'fileId',
        name: 'fileId',
        ariaLabel: 'file id associated to the file',
        fieldName: 'fileId',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true        
    },

    {
        key: 'userAlias',
        name: 'User Alias',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'createdBy',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isResizable: true,
        isPadded: true,
        isSorted: false,
        isSortedDescending: false
    },
    {
        key: 'createdDate',
        name: 'Created Date',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'createdDate',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isResizable: true,
        isPadded: true,
        isSorted: false,
        isSortedDescending: false
    },
    {
        key: 'projectNumber',
        name: 'ProjectNumber',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'payload',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,
        
    },
    {
        key: 'projectDescription',
        name: 'projectDescription',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'projectDescription',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'projectHashDescription',
        name: 'projectHashDescription',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'projectHashDescription',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'operationType',
        name: 'operationType',
        ariaLabel: 'Column operations for File type, Press to sort on File type',

        fieldName: 'operationType',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'documentSummary',
        name: 'documentSummary',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'documentSummary',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'documentTheme',
        name: 'documentTheme',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'documentTheme',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'fileRelevanceScore',
        name: 'fileRelevanceScore',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'fileRelevanceScore',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'justification',
        name: 'justification',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'justification',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'decision',
        name: 'decision',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'decision',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'tag',
        name: 'tag',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'tagResponse',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'isClassificationError',
        name: 'isClassificationError',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'isClassificationError',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,        
    },
    {
        key: 'errorMessage',
        name: 'errorMessage',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        fieldName: 'errorMessage',
        minWidth: 50,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true        
    }
    
]
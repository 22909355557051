import { IPersonaProps } from "@fluentui/react";
import { IBannerInformation } from "../../Models/Banner/IBannerInformation";
import { IProjectContext } from "../../Models/IProjectContext";
import { IProjectCreate } from "../../Models/IProjectCreate";
import { IProjectCreationResponse } from "../../Models/IProjectCreationResponse";
import { IProjectFiles } from "../../Models/IProjectFile";
import { IProjectFileTags } from "../../Models/IProjectFileTags";
import { IProjectListDetails } from "../../Models/IProjectListDetails";
import { IProjectListResponse } from "../../Models/IProjectListResponse";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { IProjectProps } from "../../Models/IProjectProps";
import { IProjectSearchRequest } from "../../Models/IProjectSearchRequest";
import { IProjectSearchResponse } from "../../Models/IProjectSearchResponse";
import { IProjectTags } from "../../Models/IProjectTags";
import { ISimilarProjectResponse } from "../../Models/ISimilarProjectResponse";
import { IRecentDocumentsResponses } from "../../Models/IRecentDocumentsResponse";
import { IRequestRecentDocuments } from "../../Models/IRequestRecentDocuments";
import {
    IRequestDeleteFile,
    IRequestDeleteMember,
    IRequestMemberStatusUpdate,
    IRequestProjectCreate,
    IRequestProjectDetails,
    IRequestProjectListDetails,
    IRequestProjectDueDate,
    IRequestProjectEdit,
    IRequestProjectSearch,
    IRequestUpdateProjectMembersNames,
    IRequestUpdateProjectOwnersNames,
    IResponseDeleteFile,
    IResponseDeleteFileFailure,
    IResponseDeleteMember,
    IResponseDeleteMemberFailure,
    IResponseMemberStatusUpdate,
    IResponseMemberStatusUpdateFailure,
    IResponseProjectCreationFailure,
    IResponseProjectCreationSuccess,
    IResponseProjectDetails,
    IResponseProjectDetailsFailure,
    IResponseProjectListDetails,
    IResponseProjectListDetailsFailure,
    IResponseProjectDueDate,
    IResponseProjectDueDateFailure,
    IResponseProjectEditFailure,
    IResponseProjectEditSuccess,
    IResponseProjectSearch,
    IResponseProjectSearchFailure,
    ISetProjectContext,
    IUpdateDeletedFiles,
    IUpdateProjectMembers,
    IUpdateProjectMembersDeleted,
    IUpdateProjectMembersSharepoint,
    IUpdateProjectMembersStatus,
    IUpdateProjectOwners,
    IUpdateProjectProps,
    IUpdateProjectStateForFileUpload,
    IUpdateTagsForFile,
    IUpdateTagsForProject,
    ProjectActionTypes,
    IRequestLoginuserProfile,
    IResponseLoginuserProfile,
    IResponseLoginUserProfileError,
    IRequestProjectStatusUpdate,
    IResponseProjectStatusUpdate,
    IResponseProjectStatusUpdateFailure,
    IRequestUploadFilesToProject,
    IResponseUploadFilesToProject,
    IResponseUploadFilesToProjectFailure,
    IRequestAddMembersToProject,
    IResponseAddMembersToProject,
    IResponseAddMembersToProjectFailure,
    IRequestGetSimilarProject,
    IResponseGetSimilarProjectFailure,
    IResponseGetSimilarProject,
    IRequestDeleteProject,
    IResponseDeleteProject,
    IResponseDeleteProjectFailure,
    ISetProjectSearchRequest,
    ISetProjectFilterListDetails,
    IRequestBannerInformation,
    IResponseBannerInformation,
    IRequestFeatureFlagInformation,
    IResponseFeatureFlagInformation,
    IResponseErrorBannerInformation,
    IRequestUserNotification,
    IResponseUserNotification,
    IResponseErrorUserNotification,
    IUpdateUserNotification,
    IClearMessageState,
    IUpdateProjectCELAPeople,
    IRequestProjectWorkArea,
    IResponseProjectWorkArea,
    IResponseErrorProjectWorkArea,
    IRequestProjectListDetailsV2,
    IRequestUserHistory,
    IResponseUserHistory,
    IResponseUserHistoryError,
    IRequestToggleBookmark,
    IResponseToggleBookmark,
    IResponseErrorToggleBookmark,
    IRequestBookmark,
    IResponseBookmark,
    IResponseBookmarkError,
    IRequestAddProjectToUserHistory,
    ICreateNewProject,
    ISetIsEditingSuccess,
    IRequestProjectFileTagsUpdate,
    IResponseProjectFileTagsUpdate,
    IResponseErrorProjectFileTagsUpdate,
    IUpdateBannerStatusInState,
    IRequestRecentDocumentsList,
    IResponseRecentDocumentsList,
    IResponseRecentDocumentsListError,
    IFailedLawFirmSearchAction,
    IRequestLawFirmSearch,
    IResponseLawFirmSearchAction,
    ISetLawFirmInfo,
    IRequestLitiagtionCases,
    IResponseLitiagtionCases,
    IResponseLitiagtionCasesError,
    IRequestLitiagtionCaseData,
    IResponseLitiagtionCaseData,
    IResponseLitiagtionCaseDataError,
    IRequestLitiagtionCaseFiles,
    IResponseLitiagtionCaseFiles,
    IResponseLitiagtionCaseFilesError,
    ISetCaseFilesFilterListDetails,
    IRequestLitiagtionFileContent,
    IResponseLitiagtionFileContent,
    IResponseLitiagtionFileContentError,
    IRequestDownloadLitiagtionCaseFiles,
    IResponseDownloadLitiagtionCaseFiles,
    IResponseDownloadLitiagtionCaseFilesError,
    IRequestLitiagtionFileURL,
    IResponseLitiagtionFileURL,
    IResponseLitiagtionFileURLError,
    IRequestLitigationFeatureFlag,
    IResponseLitigationFeatureFlag,
    IRequestIsUserPartofLitigationSgGroup,
    IIsUserPartofLitigationSgGroupResponseError,
    IResponseIsUserPartofLitigationSgGroup,
    IsProjectTeamAppLoadedInBrowser,
    IRequestLitigationSaveUserTags,
    IResponseLitigationSaveUserTags,
    IResponseLitigationSaveUserTagsError,
    IResetLitigationSaveUserTagsSuccess,
    IRequestLitigationUserQuery,
    IResponseLitigationUserQuery,
    IResponseLitigationUserQueryError,
    IRequestLitigationSaveUserQuery,
    IResponseLitigationSaveUserQuery,
    IResponseLitigationSaveUserQueryError,
    IRequestLitigationUpdateUserQuery,
    IResponseLitigationUpdateUserQuery,
    IResponseLitigationUpdateUserQueryError,
    IRequestLitigationDeleteQuery,
    IResponseLitigationDeleteQuery,
    IResponseLitigationDeleteQueryError,
    IClassifyFilesRequest,
    IClassifyFilesResponse,
    IClassifyFilesResponseError,
    IFilesClassifySummaryByUserRequest,
    IFilesClassifySummaryByUserResponse,
    IFilesClassifySummaryByUserResponseError,
    IRequestFileHistory,
    IResponseErrorFileHistory,
    IResponseFileHistory,
    IRequestProjectDescriptionHistory,
    IResponseErrorProjectDescriptionHistory,
    IResponseProjectDescriptionHistory,    
    IRequestProjectUpdateInViewScreen,
    IResponseErrorProjectUpdateInViewScreen,
    IResponseProjectUpdateInViewScreen
} from "./Project.action-types";
import { IFeatureFlagInfo } from "../../Models/IFeatureFlagInfo";

import { IUserNotificationRequest } from "../../Models/UserNotification/IUserNotificationRequest";
import { IUserNotificationResponse, IUserNotificationResponses } from "../../Models/UserNotification/IUserNotificationResponse";
import { IProjectWorkArea, IProjectWorkAreas } from "../../Models/IProjectWorkArea";
import { ProjectListFilter, ProjectListType } from "../../Helpers/ProjectEnum";
import { IHistorySearchRequest } from "src/Models/IHistorySearchRequest";
import { IHistoryResponses } from "src/Models/IHistoryResponses";
import { IBookmarkSearchRequest } from "src/Models/IBookmarkSearchRequest";
import { IBookmarkResponses } from "src/Models/IBookmarkResponses";
import { ILawFirmInfo } from "src/Models/ILawFirmInfo";
import { ILawFirmSearchRequestParams } from "src/Models/ILawFirmSearchParams";
import { ILawFirmAdaptiveDetails } from "src/Models/ILawFirmAdaptiveDetails";
import { ILitigationCaseDataRequest, ILitigationCaseFilesRequest, ILitigationCaseRequest, ILitigationFileContentRequest, ILitigationSaveUserTagsRequest } from "../../Models/ILitigationCaseRequest";
import { ILitigationCaseDataResponses, ILitigationCaseFiles, ILitigationCaseFilesResponses, ILitigationCaseResponses, ILitigationFileContentResponses, ILitigationSaveUserTagsResponses } from "src/Models/ILitigationCase";
import { ISaveQueryRequest } from "src/Models/ISaveUserQuery";
import { ILitigationSaveUserTagsResponsesError } from "src/Models/ILitigationCaseResponsesError";
import { ILitigationClassifyFileRequest } from "../../Models/ILitigationClassifyFileRequest";
import { ILitigationClassifyFileResponse } from "../../Models/ILitigationClassifyFileResponse";
import { IRequestFilesClassifySummaryByUser } from "../../Models/IRequestFilesClassifySummaryByUser";
import {IResponseFilesClassifySummaryByUser} from '../../Models/IResponseFilesClassifySummaryByUser'
import { IFileHistoryRequest } from "../../Models/FileHistory/IFileHistoryRequest";
import { IFileHistoryResponse } from "../../Models/FileHistory/IFileHistoryResponse";
import { ProjectDescriptionVersionHistoryRequest } from "../..//Models/ProjectDescription/ProjectDescriptionVersionHistoryRequest";

import { ProjectDescriptionVersionHistory } from "../../Models/ProjectDescription/ProjectDescriptionVersionHistory";

//-----------------------Recent document start------------------
export function requestRecentDocumentsList(requestRecentDocuments: IRequestRecentDocuments): IRequestRecentDocumentsList {
    return {
        type: ProjectActionTypes.REQUEST_RECENT_DOCUMENTS_LIST,
        requestRecentDocuments
    };
}

export function responseRecentDocumentsList(recentDocumentsResponses: IRecentDocumentsResponses): IResponseRecentDocumentsList {
    return {
        type: ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST,
        recentDocumentsResponses
    };
}

export function responseRecentDocumentsErrorList(error: Error,
    errorMessage: string,
    CorrelationId: string): IResponseRecentDocumentsListError {
    return {
        type: ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST_ERROR,
        error,
        errorMessage,
        CorrelationId,
    };
}
//-----------------------Recent document end------------------

export function setIsEditingSuccess(resetEditSuccessFlag: boolean): ISetIsEditingSuccess {
    return {
        type: ProjectActionTypes.SET_IS_EDIT_SUCCESS,
        resetEditSuccessFlag
    };
}

export function createNewProject(): ICreateNewProject {
    return {
        type: ProjectActionTypes.CREATE_NEW_PROJECT,
    };
}


export function requestAddProjectToUserHistory(
    projectNumber: string,
    userAlias: string
): IRequestAddProjectToUserHistory {
    return {
        type: ProjectActionTypes.REQUEST_ADD_PROJECT_TO_USER_HISTORY,
        projectNumber,
        userAlias,
    };
}

//-----------------------------------User Notification Start ----------------

export function isNotificationRead(userNotification: IUserNotificationResponse): IUpdateUserNotification {
    return {
        type: ProjectActionTypes.REQUEST_UPDATE_USER_NOTIFICATION,
        userNotification,
    };
}

export function requestUserNotification(userNotificationRequest: IUserNotificationRequest): IRequestUserNotification {
    return {
        type: ProjectActionTypes.REQUEST_USER_NOTIFICATION,
        userNotificationRequest,
    };
}

export function responseUserNotification(userNotificationResponses: IUserNotificationResponses): IResponseUserNotification {
    return {
        type: ProjectActionTypes.RESPONSE_USER_NOTIFICATION,
        userNotificationResponses
    };
}

export function responseErrorUserNotification(error: Error,
    errorMessage: string,
    CorrelationId: string): IResponseErrorUserNotification {
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_USER_NOTIFICATION,
        error,
        errorMessage,
        CorrelationId,
    };
}
//-----------------------------------User Notification End ----------------

//------------------------ Banner Information Start ------------------------
export function requestBannerInformation(loginUserAlias: string): IRequestBannerInformation {
    return {
        type: ProjectActionTypes.REQUEST_BANNER_INFORMATION,
        loginUserAlias,
    };
}

export function responseBannerInformation(bannerInformation: IBannerInformation): IResponseBannerInformation {
    return {
        type: ProjectActionTypes.RESPONSE_BANNER_INFORMATION,
        bannerInformation
    };
}

export function responseErrorBannerInformation(error: Error,
    errorMessage: string,
    CorrelationId: string): IResponseErrorBannerInformation {
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_BANNER_INFORMATION,
        error,
        errorMessage,
        CorrelationId,
    };
}

export function updateBannerStatusInState(): IUpdateBannerStatusInState {
    return {
        type: ProjectActionTypes.UPDATE_BANNER_STATUS_IN_STATE
    };
}
//------------------------ Banner Information End ------------------------

//------------------------ Feature Flag Information Start ------------------------
export function requestFeatureFlagInformation(loginUserAlias: string): IRequestFeatureFlagInformation {
    return {
        type: ProjectActionTypes.REQUEST_FEATURE_FLAG_INFORMATION,
        loginUserAlias,
    };
}

export function responseFeatureFlagInformation(featureFlagInformation: IFeatureFlagInfo): IResponseFeatureFlagInformation {
    return {
        type: ProjectActionTypes.RESPONSE_FEATURE_FLAG_INFORMATION,
        featureFlagInformation
    };
}
//------------------------ Feature Flag Information End ------------------------

//------------------------ Project Create Start ------------------------
export function requestProjectCreation(projectCreate: IProjectCreate): IRequestProjectCreate {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_CREATE,
        projectCreate,
    };
}

export function responseProjectCreationSuccess(
    projectCreationResponse: IProjectCreationResponse
): IResponseProjectCreationSuccess {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_CREATION_SUCCESS,
        projectCreationResponse,
    };
}

export function responseProjectCreationFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectCreationFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_CREATION_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
//------------------------ Project Create End ------------------------

//------------------------ Project Edit Start ------------------------
export function requestProjectEdit(projectEdit: IProjectCreate, projectContext: IProjectContext, deletedProjectMembers: IProjectMembers): IRequestProjectEdit {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_EDIT,
        projectEdit,
        projectContext,
        deletedProjectMembers
    };
}

export function responseProjectEditSuccess(
): IResponseProjectEditSuccess {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_EDIT_SUCCESS,
    };
}

export function responseProjectEditFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectEditFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_EDIT_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
//------------------------ Project Edit End ------------------------


//------------------------ Dashboard Start ------------------------

//-------------- Search Project Start --------------
export function requestProjectSearch(personal_number: string, startDate: string =''): IRequestProjectSearch {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_SEARCH,
        personal_number,
        startDate
    };
}

export function responseProjectSearch(projectSearchResponse: IProjectSearchResponse): IResponseProjectSearch {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_SEARCH,
        projectSearchResponse,
    };
}

export function responseProjectSearchFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectSearchFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_SEARCH_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
//-------------- Search Project End --------------

//-------------- Due Date of Project Start --------------
export function requestProjectDueDate(personal_number: string, endDate: string): IRequestProjectDueDate {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_DUE_DATE,
        personal_number,
        endDate
    };
}

export function responseProjectDueDate(projectDueDateResponse: IProjectSearchResponse): IResponseProjectDueDate {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE,
        projectDueDateResponse,
    };
}

export function responseProjectDueDateFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectDueDateFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
//-------------- Due Date of Project End --------------
//------------------------ Dashboard End ------------------------

// ------------------------ View Project Start ------------------------
export function requestProjectDetails(projectContext: IProjectContext, userAlias: string): IRequestProjectDetails {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_DETAILS_BY_ID,
        projectContext,
        userAlias
    };
}

export function responseProjectDetails(projectCreate: IProjectCreate): IResponseProjectDetails {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID,
        projectCreate,
    };
}

export function responseProjectDetailsFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectDetailsFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}

// ------------------------ View Project End ------------------------

//-------------- Project Detail List Start --------------

export function setProjectListContext(projectSearchRequest: IProjectSearchRequest): ISetProjectSearchRequest {
    return {
        type: ProjectActionTypes.SET_PROJECTLIST_CONTEXT,
        projectSearchRequest
    };
}

export function setProjectFilterListDetails(projectFilterListDetails: IProjectListResponse[]): ISetProjectFilterListDetails {
    return {
        type: ProjectActionTypes.SET_PROJECTFILTERLIST_DETAILS,
        projectFilterListDetails
    };
}

export function requestProjectListDetail(personal_number: string, projectSearchRequest: IProjectSearchRequest): IRequestProjectListDetails {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_Detail_List,
        projectSearchRequest,
        personal_number
    };
}

export function requestProjectListDetailV2(
    personal_number: string,
    projectSearchRequest: IProjectSearchRequest,
    projectListType: ProjectListType = ProjectListType.DEFAULT,
    filter: ProjectListFilter = ProjectListFilter.ALL,
    currentPage:number=0,
    recordsPerPage:number=10): IRequestProjectListDetailsV2 {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_DETAIL_LIST_V2,
        projectSearchRequest,
        personal_number,
        projectListType: projectListType,
        filter: filter,
        currentPage: currentPage,
        recordsPerPage:recordsPerPage
    };
}

export function responseProjectListDetail(projectListDetails: IProjectListDetails,currentPage:number): IResponseProjectListDetails {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_Detail_List,
        projectListDetails,
        currentPage:currentPage
    };
}

export function responseProjectListDetailFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectListDetailsFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_Detail_List_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
//-------------- Project Detail List End --------------

//  ------------------------ Components Update Start ------------------------
export function setProjectContext(projectContext: IProjectContext): ISetProjectContext {
    return {
        type: ProjectActionTypes.SET_PROJECT_CONTEXT,
        projectContext
    };
}

export function updateProjectStateForFileUpload(projectFiles: IProjectFiles): IUpdateProjectStateForFileUpload {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_STATE_FOR_FILE_UPLOAD,
        projectFiles,
    };
}

export function updateDeletedFiles(projectFiles: any): IUpdateDeletedFiles {
    return {
        type: ProjectActionTypes.UPDATE_DELETED_FILES,
        projectFiles,
    };
}

export function updateTagsForFile(projectFileTags: IProjectFileTags[]): IUpdateTagsForFile {
    return {
        type: ProjectActionTypes.UPDATE_TAGS_STATE_FOR_FILE,
        projectFileTags,
    };
}

export function updateTagsForProject(projectTags: IProjectTags): IUpdateTagsForProject {
    return {
        type: ProjectActionTypes.UPDATE_TAGS_STATE_FOR_PROJECT,
        projectTags,
    };
}

export function updateMembers(projectMembers: IProjectMembers): IUpdateProjectMembers {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS,
        projectMembers,
    };
}

export function updateOwners(projectOwners: IProjectMembers): IUpdateProjectOwners {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_OWNERS,
        projectOwners,
    };
}

export function updateCELAPeople(projectCELAPeople: IProjectMembers): IUpdateProjectCELAPeople {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_CELA_PEOPLE,
        projectCELAPeople,
    };
}

export function updateMembersSharepoint(projectMembersSharepoint: IProjectMembers): IUpdateProjectMembersSharepoint {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_SHAREPOINT,
        projectMembersSharepoint,
    };
}

export function updatedeletedmembers(projectMembersDeleted: any): IUpdateProjectMembersDeleted {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_DELETED,
        projectMembersDeleted,
    };
}

export function updateMembersStatusState(projectMembersToUpdate: any, responsibleFor: boolean): IUpdateProjectMembersStatus {
    // Param - projectMembersToUpdate - Member whose status needs to be updated
    //       - responsibleFor - new Status which needs to be updated
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_STATUS,
        projectMembersToUpdate,
        responsibleFor
    };
}

export function updateProjectProps(projectProps: IProjectProps): IUpdateProjectProps {
    return {
        type: ProjectActionTypes.UPDATE_PROJECT_PROPS,
        projectProps,
    };
}

export function requestUpdateMembersNames(projectMembers: IProjectMembers, userAlias: string, userName: string): IRequestUpdateProjectMembersNames {
    return {
        type: ProjectActionTypes.REQUEST_UPDATE_PROJECT_MEMBERS_NAMES,
        projectMembers,
        userAlias,
        userName
    };
}

export function requestUpdateOwnersNames(projectOwners: IProjectMembers, userAlias: string, userName: string): IRequestUpdateProjectOwnersNames {
    return {
        type: ProjectActionTypes.REQUEST_UPDATE_PROJECT_OWNERS_NAMES,
        projectOwners,
        userAlias,
        userName
    };
}

//  ------------------------ Components Update End ------------------------

// ------------------------ Delete File Start ------------------------
export function requestDeleteFile(projectContext: IProjectContext, fileName: string): IRequestDeleteFile {
    return {
        type: ProjectActionTypes.REQUEST_DELETE_FILE,
        projectContext,
        fileName
    };
}

export function responseDeleteFile(): IResponseDeleteFile {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_FILE,
    };
}

export function responseDeleteFileFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseDeleteFileFailure {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_FILE_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}

// ------------------------ Delete File End ------------------------

// ------------------------ Delete Member Start ------------------------
export function requestDeleteMember(projectContext: IProjectContext, member: any, isResponsible: boolean, projectProps: IProjectProps,lastModifiedBy): IRequestDeleteMember {
    return {
        type: ProjectActionTypes.REQUEST_DELETE_MEMBER,
        projectContext,
        member,
        isResponsible,
        projectProps,
        lastModifiedBy

    };
}

export function responseDeleteMember(): IResponseDeleteMember {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_MEMBER,
    };
}

export function responseDeleteMemberFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseDeleteMemberFailure {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_MEMBER_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}

// ------------------------ Delete Member End ------------------------

// ------------------------ Update Member Status Start ------------------------
export function requestMemberStatusUpdate(projectContext: IProjectContext, member: any, isResponsible: boolean, projectProps: IProjectProps): IRequestMemberStatusUpdate {
    return {
        type: ProjectActionTypes.REQUEST_MEMBER_STATUS_UPDATE,
        projectContext,
        member,
        isResponsible,
        projectProps
    };
}

export function responseMemberStatusUpdate(): IResponseMemberStatusUpdate {
    return {
        type: ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE,
    };
}

export function responseMemberStatusUpdateFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseMemberStatusUpdateFailure {
    return {
        type: ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Update Member Status End ------------------------

// ------------------------ Setup LoggedIn Profile Start ------------------------
export function requestLoginuserProfile(loginUserAlias: string): IRequestLoginuserProfile {
    return {
        type: ProjectActionTypes.REQUEST_LOGIN_USER_PROFILE,
        loginUserAlias,
    };
}

export function responseLoginuserProfile(loginUserpersonaProps: IPersonaProps): IResponseLoginuserProfile {
    return {
        type: ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE,
        loginUserpersonaProps,
    };
}

export function responseLoginUserProfileError(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseLoginUserProfileError {
    return {
        type: ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE_ERROR,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Setup LoggedIn Profile End ------------------------

// ------------------------ Update Project Status Start ------------------------
export function requestProjectStatusUpdate(projectContext: IProjectContext, projectProps: IProjectProps, newStatus: string): IRequestProjectStatusUpdate {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_STATUS_UPDATE,
        projectContext,
        projectProps,
        newStatus
    };
}

export function responseProjectStatusUpdate(newStatus: string): IResponseProjectStatusUpdate {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE,
        newStatus
    };
}

export function responseProjectStatusUpdateFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseProjectStatusUpdateFailure {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Update Project Status End ------------------------

export function requestFileTagsUpdate(
    projectFileTags: IProjectFileTags,
    projectContext: IProjectContext
): IRequestProjectFileTagsUpdate {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_FILE_TAGS_UPDATE,
        projectFileTags,
        projectContext,
    };
}
export function responseFileTagsUpdate(fileTags: IProjectFileTags): IResponseProjectFileTagsUpdate {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_FILE_TAGS_UPDATE,
        fileTags,
    };
}
export function responseErrorFileTagsUpdate(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseErrorProjectFileTagsUpdate {
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_PROJECT_FILE_TAGS_UPDATE,
        error,
        errorMessage,
        CorrelationId,
    };
}




// ------------------------ Upload Files to existing project Start ------------------------
export function requestUploadFilesToProject(projectContext: IProjectContext, projectProps: IProjectProps, newFiles: IProjectFiles): IRequestUploadFilesToProject {
    return {
        type: ProjectActionTypes.REQUEST_UPLOAD_FILES_TO_PROJECT,
        projectContext,
        projectProps,
        newFiles
    };
}

export function responseUploadFilesToProject(newFiles: IProjectFiles): IResponseUploadFilesToProject {
    return {
        type: ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT,
        newFiles
    };
}

export function responseUploadFilesToProjectFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseUploadFilesToProjectFailure {
    return {
        type: ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Upload Files to existing project End ------------------------


// ------------------------ Add Members to existing project Start ------------------------
export function requestAddMembersToProject(projectContext: IProjectContext, projectProps: IProjectProps, newMember: any,lastModifiedBy: string): IRequestAddMembersToProject {
    return {
        type: ProjectActionTypes.REQUEST_ADD_MEMBERS_TO_PROJECT,
        projectContext,
        projectProps,
        newMember,
        lastModifiedBy
    };
}

export function responseAddMembersToProject(newMember: any): IResponseAddMembersToProject {
    return {
        type: ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT,
        newMember
    };
}

export function responseAddMembersToProjectFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseAddMembersToProjectFailure {
    return {
        type: ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Add Members to existing project End ------------------------

// ------------------------ Get Similar Project Start ------------------------
export function getSimilarProjects(projectProps: IProjectProps, userAlias: string): IRequestGetSimilarProject {
    return {
        type: ProjectActionTypes.REQUEST_GET_SIMILAR_PROJECT,
        projectProps,
        userAlias
    };
}

export function responseGetSimilarProjects(projectSearchResponse: ISimilarProjectResponse): IResponseGetSimilarProject {
    return {
        type: ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT,
        projectSearchResponse
    };
}

export function responseGetSimilarProjectsFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseGetSimilarProjectFailure {
    return {
        type: ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}
// ------------------------ Get Similar Projects End ------------------------

// ------------------------ Delete Project Start ------------------------
export function requestDeleteProject(projectNumber: string): IRequestDeleteProject {
    return {
        type: ProjectActionTypes.REQUEST_DELETE_PROJECT,
        projectNumber
    };
}

export function responseDeleteProject(): IResponseDeleteProject {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_PROJECT,
    };
}

export function responseDeleteProjectFailure(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseDeleteProjectFailure {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_PROJECT_FAILURE,
        error,
        errorMessage,
        CorrelationId,
    };
}

// ------------------------ Delete Project End ------------------------

export function clearMessageState(): IClearMessageState {
    return {
        type: ProjectActionTypes.CLEAR_MESSAGE_STATE,
    };
}

// Project Work Area
export function requestProjectWorkArea(): IRequestProjectWorkArea {
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_WORKAREA
    };
}

export function responseProjectWorkArea(projectworkAreaResponses: IProjectWorkAreas): IResponseProjectWorkArea {
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_WORKAREA,
        projectworkAreaResponses,
    };
}

export function responseErrorProjectWorkArea(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseErrorProjectWorkArea {
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_PROJECT_WORKAREA,
        error,
        errorMessage,
        CorrelationId,
    };
}
// Project Work Area End

// User history 
export function requestUserHistory(historySearchRequest: IHistorySearchRequest): IRequestUserHistory {
    return {
        type: ProjectActionTypes.REQUEST_USER_HISTORY,
        historySearchRequest,
    }
}

export function responseUserHistory(historyResponses: IHistoryResponses): IResponseUserHistory {
    return {
        type: ProjectActionTypes.RESPONSE_USER_HISTORY,
        historyResponses,
    };
}

export function responseUserHistoryError(error: Error, errorMessage: string, CorrelationId: string): IResponseUserHistoryError {
    return {
        type: ProjectActionTypes.RESPONSE_USER_HISTORY_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

// ---- User history end ----------------

// ---- Toggle Bookmark --------------

export function requestToggleBookmark(
    isBookmarked: boolean,
    projectNumber: string,
    userAlias: string
): IRequestToggleBookmark {
    return {
        type: ProjectActionTypes.REQUEST_TOGGLE_BOOKMARK,
        isBookmarked,
        projectNumber,
        userAlias,
    };
}

export function responseToogleBookmark(bookMarkStatus: boolean): IResponseToggleBookmark {
    return {
        type: ProjectActionTypes.RESPONSE_TOGGLE_BOOKMARK,
        bookMarkStatus,
    };
}
export function responseErrorToggleBookmark(
    error: Error,
    errorMessage: string,
    CorrelationId: string
): IResponseErrorToggleBookmark {
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_TOGGLE_BOOKMARK,
        error,
        errorMessage,
        CorrelationId,
    };
}

// ----- End Toggle Bookmark-------------

// Bookmark 
export function requestBookmark(bookmarkSearchRequest: IBookmarkSearchRequest): IRequestBookmark {
    return {
        type: ProjectActionTypes.REQUEST_BOOKMARK,
        bookmarkSearchRequest,
    }
}

export function responseBookmark(bookmarkResponses: IBookmarkResponses): IResponseBookmark {
    return {
        type: ProjectActionTypes.RESPONSE_BOOKMARK,
        bookmarkResponses,
    };
}

export function responseBookmarkError(error: Error, errorMessage: string, CorrelationId: string): IResponseBookmarkError {
    return {
        type: ProjectActionTypes.RESPONSE_BOOKMARK_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

// ---- Bookmark end ----------------

//---Law firms start ------

export function requestLawFirmSearch(searchCriteria: ILawFirmSearchRequestParams): IRequestLawFirmSearch {
    return {
        type: ProjectActionTypes.REQUEST_LAWFIRM_SEARCH,
        searchCriteria
    };
}
export function responseLawFirmSearch(lawFirmAdaptiveDetails: ILawFirmAdaptiveDetails): IResponseLawFirmSearchAction {
    return {
        type: ProjectActionTypes.RESPONSE_LAWFIRM_SEARCH,
        lawFirmAdaptiveDetails,
    };
}
export function failedLawFirmSearch(error?: Error, errorMessage?: string): IFailedLawFirmSearchAction {
    return {
        type: ProjectActionTypes.FAILED_LAWFIRM_SEARCH,
        errorMessage,
        error
    };
}
export function setLawFirmInfo(lawFirmInfo: ILawFirmInfo): ISetLawFirmInfo {
    return {
        type: ProjectActionTypes.SET_LAW_FIRM_INFO,
        lawFirmInfo,
    };
}

//------------Litigation Case Details Start----------------

export function requestLitigationCases(litigationCaseRequest: ILitigationCaseRequest): IRequestLitiagtionCases {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_CASES,
        litigationCaseRequest,
    }
}

export function responseLitigationCases(litigationCaseResponses: ILitigationCaseResponses): IResponseLitiagtionCases {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASES,
        litigationCaseResponses,
    };
}

export function responseLitigationCasesError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitiagtionCasesError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASES_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function requestLitigationCaseData(litigationCaseDataRequest: ILitigationCaseDataRequest): IRequestLitiagtionCaseData {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_CASEDATA,
        litigationCaseDataRequest,
    }
}

export function responseLitigationCaseData(litigationCaseDataResponses: ILitigationCaseDataResponses): IResponseLitiagtionCaseData {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA,
        litigationCaseDataResponses,
    };
}

export function responseLitigationCaseDataError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitiagtionCaseDataError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function requestLitigationCaseFiles(litigationCaseFilesRequest: ILitigationCaseFilesRequest): IRequestLitiagtionCaseFiles {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_CASEFILES,
        litigationCaseFilesRequest,
    }
}

export function requestDownloadLitigationCaseFiles(litigationCaseFilesRequest: ILitigationCaseFilesRequest): IRequestDownloadLitiagtionCaseFiles {
    return {
        type: ProjectActionTypes.REQUEST_DOWNLOAD_LITIGATION_CASEFILES,
        litigationCaseFilesRequest,
    }
}

export function setCaseFilesFilterListDetails(caseFilesFilterListDetails: ILitigationCaseFiles[]): ISetCaseFilesFilterListDetails {
    return {
        type: ProjectActionTypes.SET_CASEFILESFILTERLIST_DETAILS,
        caseFilesFilterListDetails
    };
}

export function responseLitigationCaseFiles(litigationCaseFilesResponses: ILitigationCaseFilesResponses): IResponseLitiagtionCaseFiles {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES,
        litigationCaseFilesResponses,
    };
}

export function responseDownloadLitigationCaseFiles(): IResponseDownloadLitiagtionCaseFiles {
    return {
        type: ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES,

    };
}

export function responseLitigationCaseFilesError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitiagtionCaseFilesError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function responseDownloadLitigationCaseFilesError(error: Error, errorMessage: string, CorrelationId: string): IResponseDownloadLitiagtionCaseFilesError {
    return {
        type: ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function requestLitigationFileContent(litigationFileContentRequest: ILitigationFileContentRequest): IRequestLitiagtionFileContent {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_FILECONTENT,
        litigationFileContentRequest,
    }
}

export function responseLitigationFileContent(litigationFileContentResponses: ILitigationFileContentResponses): IResponseLitiagtionFileContent {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT,
        litigationFileContentResponses,
    };
}

export function responseLitigationFileContentError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitiagtionFileContentError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function requestLitigationFileURL(litigationFileURLRequest: ILitigationFileContentRequest): IRequestLitiagtionFileURL {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_FILEURL,
        litigationFileURLRequest,
    }
}

export function responseLitigationURL(litigationFileURLResponses: ILitigationFileContentResponses): IResponseLitiagtionFileURL {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_FILEURL,
        litigationFileURLResponses,
    };
}

export function responseLitigationURLError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitiagtionFileURLError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_FILEURL_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

export function requestLitigationFeatureFlag(loginUserAlias: string, featureName: string): IRequestLitigationFeatureFlag {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_FEATURE_FLAG,
        loginUserAlias,
        featureName
    };
}

export function responseLitigationFeatureFlag(litigationFeatureFlagResponse: IFeatureFlagInfo): IResponseLitigationFeatureFlag {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_FEATURE_FLAG,
        litigationFeatureFlagResponse
    };
}

export function requestSaveUserTags(litigationSaveUserTagsRequest: ILitigationSaveUserTagsRequest): IRequestLitigationSaveUserTags {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_SAVEUSERTAGS,
        litigationSaveUserTagsRequest,
    }
}

export function responseSaveUserTags(litigationSaveUserTagsResponses: ILitigationSaveUserTagsResponses): IResponseLitigationSaveUserTags {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS,
        litigationSaveUserTagsResponses,
    };
}

export function resetSaveUserTagsSuccess(resetSaveUsertagsSuccess: boolean): IResetLitigationSaveUserTagsSuccess {
    return {
        type: ProjectActionTypes.RESET_LITIGATION_SAVEUSERTAGS_SUCCESS,
        resetSaveUsertagsSuccess,
    };
}

export function responseSaveUserTagsError(error: Error, errorMessage: string, CorrelationId: string): IResponseLitigationSaveUserTagsError {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS_ERROR,
        error,
        errorMessage,
        CorrelationId
    }
}

//------------Litigation Case Details End------------------

//-------------------Litigation user Permission Check Start --------------
export function requestIsUserPartofLitigationSgGroup(userAlias: string): IRequestIsUserPartofLitigationSgGroup {
    return {
        type: ProjectActionTypes.REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP,
        userAlias
    };
}
export function responseIsUserPartofLitigationSgGroup(isuserPartOfLitigationSGGroup: boolean): IResponseIsUserPartofLitigationSgGroup {
    return {
        type: ProjectActionTypes.RESPONSE_IS_USER_PART_OF_LITIGATION_SG_GROUP,
        isuserPartOfLitigationSGGroup
    };
}
export function isUserPartofLitigationSgGroupResponseError(error: Error, errorMessage: string, CorrelationId: string): IIsUserPartofLitigationSgGroupResponseError {
    return {
        type: ProjectActionTypes.IS_USER_PART_OF_LITIGATION_SG_GROUP_RESPONSE_ERROR,
        error,
        errorMessage,
        CorrelationId
    };
}
//-------------------Litigation user Permission Check End --------------
//-------------------Litigation user Permission Check End --------------
export function isProjectTeamAppLoadedInBrowser(isLoadedInBrowser: boolean): IsProjectTeamAppLoadedInBrowser {
    return {
        type: ProjectActionTypes.IS_PROJECT_TEAM_APP_LOADED_IN_BROWSER,
        isLoadedInBrowser
    }
}
//----------------Is app loading in browser--------------

// Action Creator for Request Litigation User Query
export const requestLitigationUserQuery = (projectNumber: string, userAlias: string): IRequestLitigationUserQuery => {
    return {
        type: ProjectActionTypes.REQUEST_LITIGATION_USER_QUERY,
        projectNumber,
        userAlias
    };
};

// Action Creator for Response Litigation User Query
export const responseLitigationUserQuery = (queries: any[]): IResponseLitigationUserQuery => {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY,
        queries
    };
};

// Action Creator for Response Litigation User Query Error
export const responseLitigationUserQueryError = (error: Error, errorMessage: string, CorrelationId: string): IResponseLitigationUserQueryError => {
    return {
        type: ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY_ERROR,
        error,
        errorMessage,
        CorrelationId
    };
};

// Action Creator for Request Save User Query
export const requestSaveUserQuery = (payload: ISaveQueryRequest, userAlias: string, projectNumber: string): IRequestLitigationSaveUserQuery => {
    return {
        type: ProjectActionTypes.REQUEST_SAVE_USER_QUERY,
        payload,
        userAlias,
        projectNumber
    };
};

// Action Creator for Response Save User Query
export const responseSaveUserQuery = (queries: any[]): IResponseLitigationSaveUserQuery => {
    return {
        type: ProjectActionTypes.RESPONSE_SAVE_USER_QUERY,
        queries
    };
};

// Action Creator for Error in Save User Query
export const saveUserQueryError = (error: Error, errorMessage: string, correlationId: string): IResponseLitigationSaveUserQueryError => {
    return {
        type: ProjectActionTypes.RESPONSE_SAVE_USER_QUERY_ERROR,
        error,
        errorMessage,
        correlationId
    };
};

export const requestUpdateUserQuery = (payload: ISaveQueryRequest, userAlias: string, projectNumber: string,queryID: string): IRequestLitigationUpdateUserQuery => {
    return {
        type: ProjectActionTypes.REQUEST_UPDATE_USER_QUERY,
        payload,
        userAlias,
        projectNumber,
        queryID
    };
};

// Action Creator for Response Save User Query
export const responseUpdateUserQuery = (queries: any[]): IResponseLitigationUpdateUserQuery => {
    return {
        type: ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY,
        queries
    };
};

// Action Creator for Error in Save User Query
export const updateUserQueryError = (error: Error, errorMessage: string, correlationId: string): IResponseLitigationUpdateUserQueryError => {
    return {
        type: ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY_ERROR,
        error,
        errorMessage,
        correlationId
    };
};

export const requestDeleteUserQuery = (queryID: string, projectNumber: string,userAlias: string ): IRequestLitigationDeleteQuery => {
    return {
        type: ProjectActionTypes.REQUEST_DELETE_USER_QUERY,
        queryID,
        projectNumber,
        userAlias
    };
};

// Action Creator for Response Delete Query
export const responseDeleteUserQuery = (queries: any[]): IResponseLitigationDeleteQuery => {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_USER_QUERY,
        queries
    };
};

// Action Creator for Error in Delete Query
export const deleteUserQueryError = (error: Error, errorMessage: string, correlationId: string): IResponseLitigationDeleteQueryError => {
    return {
        type: ProjectActionTypes.RESPONSE_DELETE_USER_QUERY_ERROR,
        error,
        errorMessage,
        correlationId
    };
};

export const classifyFilesRequest = (litigationClassifyFileRequest: ILitigationClassifyFileRequest): IClassifyFilesRequest =>{
    return {
        type: ProjectActionTypes.CLASSIFY_FILES_REQUEST,
        litigationClassifyFileRequest
    }
};

export const classifyFilesResponse = (litigationClassifyFileResponse: ILitigationClassifyFileResponse): IClassifyFilesResponse =>{
    return {
        type: ProjectActionTypes.CLASSIFY_FILES_RESPONSE,
        litigationClassifyFileResponse
    }
};

export const classifyFilesResponseError = (error: Error, errorMessage: string, correlationId: string): IClassifyFilesResponseError =>{
    return {
        type: ProjectActionTypes.CLASSIFY_FILES_RESPONSE_ERROR,
        error,
        errorMessage,
        correlationId
    }
};

export const filesClassifySummaryByUserRequest = (requestFilesClassifySummaryByUser: IRequestFilesClassifySummaryByUser):IFilesClassifySummaryByUserRequest=>{
    return {
        type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST,
        requestFilesClassifySummaryByUser
    }
}

export const filesClassifySummaryByUserResponse = (responseFilesClassifySummaryByUser: IResponseFilesClassifySummaryByUser):IFilesClassifySummaryByUserResponse=>{
    return {
        type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE,
        responseFilesClassifySummaryByUser
    }
}

export const filesClassifySummaryByUserResponseError = (error: Error, errorMessage: string, correlationId: string): IFilesClassifySummaryByUserResponseError =>{
    return {
        type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE_ERROR,
        error,
        errorMessage,
        correlationId
    }
};

export const requestFileHistory = (fileHistoryRequest: IFileHistoryRequest): IRequestFileHistory=>{
    return {
        type: ProjectActionTypes.REQUEST_FILE_HISTORY,
        fileHistoryRequest
    }
}
 
export const responseFileHistory = (fileHistoryResponses: IFileHistoryResponse[]): IResponseFileHistory=>{
   
    return {
        type: ProjectActionTypes.RESPONSE_FILE_HISTORY,
        fileHistoryResponses
    }
}
 
export const responseErrorFileHistory = (error: Error, errorMessage: string, correlationId: string): IResponseErrorFileHistory=>{
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_FILE_HISTORY,
        error,
        errorMessage,
        correlationId
    }
}


export const requestProjectDescriptionHistory = (projectDescriptionVersionHistoryRequest: ProjectDescriptionVersionHistoryRequest): IRequestProjectDescriptionHistory=>{
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_DESCRIPTION_HISTORY,
        projectDescriptionVersionHistoryRequest
    }
}
 
export const responseProjectDescriptionHistory = (projectDescriptionVersionHistoryList?: ProjectDescriptionVersionHistory[]): IResponseProjectDescriptionHistory=>{
   
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DESCRIPTION_HISTORY,
        projectDescriptionVersionHistoryList
    }
}
 
export const responseErrorProjectDescriptionHistory = (error: Error, errorMessage: string, correlationId: string): IResponseErrorProjectDescriptionHistory=>{
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_DESCRIPTION_ERROR_HISTORY,
        error,
        errorMessage,
        correlationId
    }
}

export const requestProjectUpdateInViewScreen = (projectUpdate:IProjectCreate): IRequestProjectUpdateInViewScreen=>{
    return {
        type: ProjectActionTypes.REQUEST_PROJECT_UPDATE_IN_VIEW_SCREEN,
        projectUpdate
    }
}
 
export const responseProjectUpdateInViewScreen = (projectDescription: string, isDescriptionUseForClassify: boolean): IResponseProjectUpdateInViewScreen=>{
   
    return {
        type: ProjectActionTypes.RESPONSE_PROJECT_UPDATE_IN_VIEW_SCREEN,
        projectDescription,
        isDescriptionUseForClassify
    }
}

export const responseErrorProjectUpdateInViewScreen = (error: Error, errorMessage: string, correlationId: string): IResponseErrorProjectUpdateInViewScreen=>{
   
    return {
        type: ProjectActionTypes.RESPONSE_ERROR_PROJECT_UPDATE_IN_VIEW_SCREEN,
        error,
        errorMessage,
        correlationId
    }
}


import { useState, useEffect, useCallback } from "react";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { requestProjectDescriptionHistory } from "../../../../../Shared/Actions/Project.action";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { ProjectDescriptionVersionHistoryRequest } from "../../../../../Models/ProjectDescription/ProjectDescriptionVersionHistoryRequest";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { Reducer } from "redux";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { ProjectDescriptionVersionHistory } from "../../../../../Models/ProjectDescription/ProjectDescriptionVersionHistory";
export const useProjectDescriptionHistory = (projectProps, reduxContext) => {
    const [isViewingHistory, setIsViewingHistory] = useState(false);
    const [descriptionHistory, setDescriptionHistory] = useState<ProjectDescriptionVersionHistory[]>([]);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;

    const { projectDescriptionVersionHistoryResponse, isProjectDescriptionVersionHistoryLoading, loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const fetchDescriptionHistory = useCallback(() => {
        if (projectProps?.number) {
            const request: ProjectDescriptionVersionHistoryRequest = { projectNumber: projectProps.number, userAlias: loginUserpersonaProps?.secondaryText};
            dispatch(requestProjectDescriptionHistory(request));
            setIsViewingHistory(true);
        }
    }, [dispatch, projectProps]);

    const collapseHistory = useCallback(() => setIsViewingHistory(false), []);

    useEffect(() => {
        if (projectDescriptionVersionHistoryResponse?.length>0) {
            setDescriptionHistory(projectDescriptionVersionHistoryResponse);
        }
    }, [projectDescriptionVersionHistoryResponse]);

    return {
        isViewingHistory,
        descriptionHistory,
        isLoading: isProjectDescriptionVersionHistoryLoading,
        fetchDescriptionHistory,
        collapseHistory,
    };
};


import { Stack, TextField, Icon } from '@fluentui/react';
import React, { useEffect, useState, useRef } from 'react';
import { Reducer } from 'redux';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { ChatReducerName, chatInitialState, chatReducer  } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { setNewUserPrompt } from '../../../../Shared/Actions/Chat.action';



import './UserPrompt.styles.css'
import { IChatState } from '../../../../Shared/IChatState';
import Disabled from '../../../../Common/Components/Disabled';
const Userprompt = () => {
  const textInputRef = useRef(null);
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const { useSelector, dispatch } = reduxContext;
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { isRequestingNewAnswer, isResponseNewAnswerError, topic } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);

  const [chatInput, setChatInput] = useState<string>('');
  const [charactersCount, setCharactersCount] = useState<number>(chatInput.length);
  const handleInputChange = (event: any) => {
    setChatInput(event?.target?.value || '');
    setCharactersCount(event?.target?.value?.length)
  };

  const submitUserPrompt = () => {
		if (chatInput?.trim()?.length > 0) {
			dispatch(setNewUserPrompt(chatInput));
			setChatInput('');
			setCharactersCount(0);
		}
	};

  const handleKeyDown = (event: any) => {
		if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
			event.preventDefault();
			if (event.target) {
				event.target.select();
			}
		}
		if (chatInput?.trim()?.length > 2000 && event.key !== 'Delete' && event.key !== 'Backspace' && event.key !== 'Enter') {
			event.preventDefault();
			return;
		}
		if (chatInput?.trim()?.length <= 0 || event?.target?.value?.length === 0) {
			return;
		}
    setCharactersCount(event?.target?.value?.length)
		if (event.key === 'Enter' && chatInput?.trim()?.length > 0) {
			submitUserPrompt();
			setChatInput('');
			setCharactersCount(0);
      textInputRef.current.focus();
		}    
	};

  useEffect(()=>{
    textInputRef?.current?.focus()
    setCharactersCount(chatInput.length)

  },[])

  useEffect(() => {
    setChatInput(chatInput.substring(0, 2000));
    setCharactersCount(chatInput.length);
  }, [chatInput]);

  useEffect(() => {
    if (topic && Object.keys(topic).length > 0)  {
      dispatch(setNewUserPrompt(""));
    }
  }, [topic, isResponseNewAnswerError]);

  return (
    <Stack>
      <Disabled disabled={isRequestingNewAnswer ? true : false}>
        <Stack className='userprompt-container'>
          <Stack className='userprompt-textcontainer'>
            <textarea
              onChange={handleInputChange}
              value={chatInput}
              placeholder={chatInput ? '' : "Ask a question"}
              ref={textInputRef}
              className='text-control'
              aria-label='enter the question for which you want the reponse from cela web'
              onKeyDown={handleKeyDown}
            />
          </Stack>
          <Stack className='bottom-container' horizontal>
            <Stack className='remaining-characters'>{charactersCount.toString()}/2000</Stack>
            <Stack className='empty-gap'></Stack>
            <Stack className="send-button-container">
              <Stack className="send-button">
                <Disabled disabled={charactersCount <= 0 ? true : false}>
                  <Stack>
                    <div 
                      tabIndex={0} 
                      style={{ cursor: 'pointer' }} 
                      onClick={submitUserPrompt}  
                      onKeyDown={handleKeyDown} 
                      aria-label='Click on the button to send the question to open ai'>
                        <Icon iconName='Send'/>
                    </div>
                  </Stack>
                </Disabled>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

      </Disabled>
    </Stack>
  )
}
export default Userprompt
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, mergeStyleSets, SelectionMode, Stack, TooltipHost } from "@fluentui/react";
import React from "react"
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../../Shared/ProjectState";
import { IFileHistoryResponse } from "../../../../../../Models/FileHistory/IFileHistoryResponse";
import { truncateText } from "../../../../../../Helpers/ProjectHelper";
import { FileHistoryColumns } from './FileHistoryColumns';
import { fileHistoryItemsStyles } from "./FileHistoryItems.styles";

const renderHeader = (headerText: string) => (
    <Stack horizontal>
        <span style={{ marginTop: 12 }}>{headerText}</span>
    </Stack>
);


export interface IFileHistoryItemsProps {
    fileId?: string;
}

const FileHistoryItems = (fileHistoryItemsProps: IFileHistoryItemsProps) => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const { fileHistoryResponses
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const { fileId } = fileHistoryItemsProps;
    const [fileHistoryList, setFileHistoryList] = React.useState<IFileHistoryResponse[]>([])

    const columns: IColumn[] = FileHistoryColumns.map((fileHistoryColumn: IColumn) => {
        if (fileHistoryColumn.key === 'userAlias') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Created by'),
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'fileId') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('File Id'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            {fileId}
                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'createdDate') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Created date'),
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'projectNumber') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Project number'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            {item?.projectNumber}
                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'projectDescription') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Project description'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            <TooltipHost content={item?.projectDescription}>
                                {truncateText(item?.projectDescription, 40, 40)}
                            </TooltipHost>

                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'projectHashDescription') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Project hash description'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            <TooltipHost content={item?.projectHashDescription}>
                                {item?.projectHashDescription}
                            </TooltipHost>

                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'operationType') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Operation type'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            <TooltipHost content={item?.operationType}>
                                {item?.operationType}
                            </TooltipHost>

                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'documentSummary') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Document summary'),
                onRender: (item: IFileHistoryResponse) => {
                    return (<>
                        {item?.operationType?.toLocaleLowerCase() === 'classification' ? (
                            <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                                <TooltipHost content={item?.fileClassification?.openAICaseFileClassificationResponse?.documentSummary}>
                                    {truncateText(item?.fileClassification?.openAICaseFileClassificationResponse?.documentSummary, 40, 40)}
                                </TooltipHost>
                            </div>) : null}
                    </>
                    )
                }

            } as IColumn;
        }
        if (fileHistoryColumn.key === 'documentTheme') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Document theme'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <>
                            {item?.operationType?.toLocaleLowerCase() === 'classification' ? (
                                <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                                    <TooltipHost content={item?.fileClassification?.openAICaseFileClassificationResponse?.documentTheme}>
                                        {truncateText(item?.fileClassification?.openAICaseFileClassificationResponse?.documentTheme, 40, 40)}
                                    </TooltipHost>
                                </div>) : null}
                        </>
                    )
                }

            } as IColumn;
        }
        if (fileHistoryColumn.key === 'fileRelevanceScore') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Relevance score'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <>
                            {item?.operationType?.toLocaleLowerCase() === 'classification' ? (
                                <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>

                                    {item?.fileClassification?.openAICaseFileClassificationResponse?.fileRelevanceScore}

                                </div>) : null}
                        </>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'justification') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Justification'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <>
                            {item?.operationType?.toLocaleLowerCase() === 'classification' ? (
                                <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                                    <TooltipHost content={item?.fileClassification?.openAICaseFileClassificationResponse?.justification}>
                                        {truncateText(item?.fileClassification?.openAICaseFileClassificationResponse?.justification, 40, 40)}
                                    </TooltipHost>
                                </div>) : null}
                        </>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'decision') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Decision'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <>
                            {item?.operationType?.toLocaleLowerCase() === 'classification' ? (
                                <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                                    <TooltipHost content={String(item?.fileClassification?.openAICaseFileClassificationResponse?.decision)||""}>
                                        {truncateText(String(item?.fileClassification?.openAICaseFileClassificationResponse?.decision)||"", 40, 40)}
                                    </TooltipHost>
                                </div>) : null}
                        </>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'tag') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Tag'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <>
                            {item?.operationType?.toLocaleLowerCase() === 'autotag' ? (
                                <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                                    <TooltipHost content={item?.fileClassification?.openAICaseFileClassificationResponse?.tagResponse}>
                                        {truncateText(item?.fileClassification?.openAICaseFileClassificationResponse?.tagResponse, 40, 40)}
                                    </TooltipHost>
                                </div>) : null}
                        </>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'isClassificationError') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Is classification failure?'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            {item?.fileClassification?.isClassificationError && (
                                <>
                                    True
                                </>
                            )}
                        </div>
                    )
                }
            } as IColumn;
        }
        if (fileHistoryColumn.key === 'errorMessage') {
            return {
                ...fileHistoryColumn,
                onRenderHeader: () => renderHeader('Error message'),
                onRender: (item: IFileHistoryResponse) => {
                    return (
                        <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                            {item?.fileClassification?.exception}
                        </div>
                    )
                }
            } as IColumn;
        }
    })


    const [fileHistoryColumns, setFileHistoryColumns] = React.useState<IColumn[]>();

    React.useEffect(() => {
        setFileHistoryList([])
    }, []);
    React.useEffect(() => {
        setFileHistoryColumns(columns);
        setFileHistoryList(fileHistoryResponses)
    }, [fileHistoryResponses]);
    return (<>

        <div style={{ backgroundColor: 'white', width: '100%', maxWidth: '100%', overflowY: 'auto' }} >
            <Stack>
                <Stack>
                    {fileHistoryResponses?.length > 0 && (
                        <Stack>
                            <Stack style={{ marginTop: '5px', width: '100%' }}>
                                <DetailsList
                                    checkboxVisibility={CheckboxVisibility.always}
                                    items={fileHistoryList}
                                    columns={fileHistoryColumns}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    enterModalSelectionOnTouch={true}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="select"
                                    onRenderItemColumn={(item, index, column) => {
                                        return (
                                            <div style={{ userSelect: 'text' }}>
                                                {item[column.fieldName]}
                                            </div>
                                        );
                                    }}
                                    data-is-scrollable={true}
                                    styles={fileHistoryItemsStyles}
                                />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </div>
    </>)
}

export default FileHistoryItems;